export const getBase64 = (file: File, callback:(result:string) => void) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        if(typeof reader.result === "string")
            callback(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}