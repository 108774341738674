import * as React from "react";
import FormPost from "./FormPost";
import style from "../styles/accueilConfig.module.scss";
import { Post } from "../../../../services/object/Post";
import { SalepointData } from "../../../../services/object/Salepoint";

interface ModalPostProps {
  errorMessages: Record<string, string>;
  handlePostChange: (property: string, value: string | number | SalepointData | null) => void;
  formPost: Post | undefined;
  formRefPost: React.RefObject<HTMLFormElement>;
}

export default function ModalPost({
  errorMessages,
  handlePostChange,
  formPost,
  formRefPost,
}: ModalPostProps) {
  return [
    <>
      <form className={`${style.uiform} ${style.postForm}`} ref={formRefPost}>
        <FormPost
          onChange={handlePostChange}
          formPost={formPost}
          errorMessages={errorMessages}
        />
      </form>
    </>
  ];
}
