import React, { FormEvent, useState } from 'react';
import style from './forgetPassword.module.scss';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Button, Typography } from '@mui/material';
import {Service_Api} from '../../services/Api';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';


const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [send, setSend] = useState(false);
    const [error, setError] = useState(false);
    const [error2, setError2] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [nextStep, setNextStep] = useState(searchParams.get("token"));
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()

    const Api = Service_Api();
    const handleForgetPassword = async (e: React.MouseEvent<HTMLButtonElement>|FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        let body = {
            email: email
        }
        let response = (await (Api.post('user/forgetPassword', body, {})))
        if(response?.success){
            setSend(true)
        }
    };
    const handleChangePassword = async (e: React.MouseEvent<HTMLButtonElement>|FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(password === password2){
            let body = {
                password: password,
                token: searchParams.get("token"),
            }
            let response = (await (Api.post('user/createPassword', body, {})))
            if(response?.success){
                setError(false);
                setError2(false);
                setSend(true)
                setTimeout(function(){
                    if(searchParams.get("app") && atob(searchParams.get("app") ?? "") !== ""){
                        window.location.href = atob(searchParams.get("app") ?? "")
                    }else{
                        navigate('/login', {replace:true})
                    }
                }, 2000)
            }else{
                setError(true);
            }
        }else{
            setError2(true);
        }
    };
    
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <div className={style.content}>
            <div className={((!nextStep) ? style.login : style.hidden)}>
                <div className={style.container_form}>
                    <div className={style.heading}>
                        <h1>Mot de passe oublié ?</h1>
                        <p>Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.</p>
                    </div>
                    <form onSubmit={(e) => { e.preventDefault(); handleForgetPassword(e); }}>

                        <label htmlFor="Email de connexion"></label>
                        
                        <TextField  id="email"
                                    color='secondary' 
                                    label="Votre email"
                                    name="email"
                                    autoComplete="email"
                                    required
                                    onChange={e => setEmail(e.target.value)}
                                    sx={{ m: 1, width: '100%' }}
                                    />
                        <Typography className={error ? '' : style.hidden} color="error">
                            Oups, votre adresse est inconnue.
                        </Typography>

                        <div className="button-container">
                            <Button variant="outlined" color='secondary' className="main forgetPassword_js" onClick={(e) => handleForgetPassword(e)}>
                                Envoyer
                            </Button>
                        </div>
                        <Link to={'/login'} title="Revenir à la connexion">
                            <span>Retour à la connexion</span>
                        </Link>
                    </form>
                    <div className={((send) ? style.info : style.hidden)}>
                        Vous allez recevoir un mail dans les prochaines minutes pour réinitialiser votre mot de passe. Ce lien sera valide pendant 2 heures.
                    </div>
                </div>
            </div>
            <div className={((nextStep) ? style.login : style.hidden)}>
                <div className={style.container_form}>
                    <div className={style.heading}>
                        <h1>Nouveau mot de passe</h1>
                        <p>Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.</p>
                    </div>
                    <form action="" method="POST" onSubmit={(e)=>{handleChangePassword(e)}}>
                        <FormControl sx={{ m: 1, width: '100%' }} required>
                            <InputLabel color='secondary' htmlFor="password">Votre nouveau mot de passe</InputLabel>
                            <OutlinedInput
                                id="password"
                                color='secondary'
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                                <InputAdornment color='secondary' position="end">
                                                    <IconButton aria-label="toggle password visibility"
                                                                color='secondary'
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end" >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                }
                                name="password"
                                autoComplete="password"
                                onChange={e => setPassword(e.target.value)}
                                label="Mot de passe"
                                required
                                error={error}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, width: '100%' }} required>
                            <InputLabel color='secondary' htmlFor="password2">Confirmez votre mot de passe</InputLabel>
                            <OutlinedInput
                                id="password2"
                                color='secondary'
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                                <InputAdornment color='secondary' position="end">
                                                    <IconButton color='secondary' 
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end" >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                }
                                name="password"
                                autoComplete="password"
                                onChange={e => setPassword2(e.target.value)}
                                label="Mot de passe"
                                required
                                error={error}
                            />
                        </FormControl>
                        
                        <Typography className={((error) ? "" : style.hidden)} 
                                    color="error">
                                    Oups, une erreur est subvenu. Merci de contacter "dev@autobonplan.com".
                        </Typography>

                        <Typography className={((error2) ? "" : style.hidden)} 
                                    color="error">
                                    Les mots de passes ne correspondent pas.
                        </Typography>
                        <div className="button-container">
                            <Button variant="outlined" color='secondary' type="submit" onClick={(e)=>{handleChangePassword(e)}}>
                                Changer
                            </Button>
                        </div>
                    </form>
                    <div className={((send) ? style.info : style.hidden)}>
                        Votre mot de passe a bien été changé.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgetPassword;
