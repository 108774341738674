import React, { useState, useEffect, ChangeEvent } from "react";

import style from "./assets/style/profile.module.scss";

import { App } from "../../services/object/App";

import TextField from "@mui/material/TextField";

interface AppFormProps {
  onChange: (property: string, value: string) => void;
  appForm: App | undefined;
  errorMessages: Record<string, string>;
}

const AppForm = ({
  onChange,
  appForm,
  errorMessages,
}: AppFormProps) => {
  const [localFormValues, setLocalFormValues] = useState({
    name: appForm?.name ?? "",
    url: appForm?.url ?? "",
  });

  useEffect(() => {
    if (appForm) {
      setLocalFormValues({
        name: appForm.name ?? "",
        url: appForm.url ?? "",
      });
    }
  }, [appForm]);

  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  return (
    <div className={style.content}>
      <TextField
        fullWidth
        label="Nom"
        size="small"
        name="name"
        value={localFormValues.name}
        onChange={handleLocalChange}
        onBlur={handleBlur}
        required
        error={!!errorMessages.name}
        helperText={errorMessages.name}
        className={style.input}
      />
      <TextField
        fullWidth
        label="Url"
        size="small"
        name="url"
        value={localFormValues.url}
        onChange={handleLocalChange}
        onBlur={handleBlur}
        className={style.input}
        required
        error={!!errorMessages.url}
        helperText={errorMessages.url}
      />
    </div>
  );
};

export default AppForm;
