import style from "./assets/style/profile.module.scss";
import ChangeProfil from "./ChangeProfile";
import ChangePassword from "./ChangePassword";
import ChangeApps from "./ChangeApps";

function Profile() {
  return (
    <div className={`background ${style.profileBackground}`}>
      <div id={style.profile}>
        <ChangeProfil />

        <div className={style.column}>
            <ChangePassword />
            <ChangeApps />
        </div>
      </div>
    </div>
  );
}

export default Profile;
