import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../App.scss";
import style from "./assets/scss/accueil.module.scss";
import base_doc from "./assets/images/doc-jra/base-documentaire.jpg";
import offres_emploi from "./assets/images/doc-jra/offres-d-emploi.png";
import handicap from "./assets/images/doc-jra/accompagnement-handicap.jpg";
import e_learning from "./assets/images/doc-jra/e-learning.jpg";
import charte_ethique from "./assets/images/doc-jra/charte-ethique.jpg";
import livret_accueil from "./assets/images/doc-jra/livret-d-accueil.jpg";

import { Service_Api } from "../../services/Api";
import { useAuth } from "../../services/auth/useAuth";
import {
  Post,
  postCategoriesNews,
  postCategoriesNewEmployee,
} from "../../services/object/Post";
import { App } from "../../services/object/App";
import { jsDateToLocalFr } from "../../services/tools/translateDate";
import { truncateText } from "../../services/tools/truncateText";

import { Loader } from "../../components/loader/Loader";

import NewEmployeeModal from "./NewEmployeeModal";

import noPhotoPost from "./assets/images/noPhotoPost.png";
import noPhotoNewEmployee from "./assets/images/noPhotoNewEmployee.jpg";
import youtubeLogo from "./assets/images/youtubeLogo.png";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import Carousel from "react-material-ui-carousel";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExtensionIcon from "@mui/icons-material/Extension";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function Accueil() {
  const Api = Service_Api();
  const { user } = useAuth();

  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      await fetchInitialData();

      setTimeout(function () {
        setLoader(false);
      }, 1000);
    };

    fetchData();
  }, []);
  const fetchInitialData = async () => {
    await Promise.all([fetchAllPosts(), fetchAppsByUserUid()]);
  };

  const [TOPost, setTOPost] = useState<Post[]>([]);
  const [TOUserApp, setTOUserApp] = useState<App[]>([]);
  const [TOUrlApp, setTOUrlApp] = useState<string[]>([""]);

  const [newEmployeeData, setNewEmployeeData] = useState<Post>({
    uid: "",
    title: "",
    subtitle: "",
    description: "",
    url: "",
    urlYoutube: "",
    photo: "",
    newEmployeeJob: "",
    univers: {
      uid: "",
      name: ""
    },
    salepoint: {
      uid: "",
      name: ""
    },
    postCategoryId: 0,
    isImportantPost: false,
    likeNumber: 0,
    isDraft: false,
    isSuggestedPost: false,
    createdBy: null,
    date: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  });
  const [isNewEmployeeModalOpen, setIsNewEmployeeModalOpen] = useState(false);

  // fetch data
  const fetchAllPosts = async () => {
    try {
      const response = await Api.get("post/");
      const posts = response?.data;
      const publishedPosts = posts.filter(
        (post: any) => post.isDraft === false
      );
      setTOPost(publishedPosts);
      console.log("Données récupérées avec succès", publishedPosts);
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  };
  const fetchAppsByUserUid = async () => {
    try {
      const response = await Api.get(
        "app/getAppsOrderByUserUid",
        "userUid=" + user.uid
      );
      const T_userApps = response?.data;
      setTOUserApp(T_userApps);
      console.log("Données récupérées avec succès", response);
      fetchFavicon(response?.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données");
    }
  };
  const fetchFavicon = async (TOUrl: [App]) => {
    let TJSXApp = [];
    if (TOUrl && TOUrl.length > 0) {
      for (const OUrl of TOUrl) {
        let url = OUrl.url;
        try {
          const response = await fetch(url);
          const html = await response.text();
          const iconMatch = html.match(
            /<link\s+[^>]*rel=["'](?:shortcut icon|icon)["'][^>]*href=["']([^"']+)["']/i
          );

          if (iconMatch && iconMatch[1]) {
            const iconUrl = new URL(iconMatch[1], url).href;

            try {
              const iconResponse = await fetch(iconUrl, { method: "HEAD" });

              if (iconUrl.includes("callcenter"))
                console.log('iconResponse:', iconResponse)

              if (iconResponse.ok) {
                TJSXApp.push(iconUrl);
              } else {
                TJSXApp.push("");  // URL inaccessible
              }
            } catch (iconError) {
              console.error("Error fetching icon:", iconError);
              TJSXApp.push("");  // Erreur d'accès à l'icône
            }
          } else {
            TJSXApp.push("");  // Pas d'icône trouvée
          }

        } catch (error) {
          console.error("Error fetching favicon:", error);
          TJSXApp.push("");
        }
      }
    }
    setTOUrlApp(TJSXApp);
  };


  const extractYouTubeId = (url: string): string => {
    const regExp =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([^#&?\/\n]+)(?:\S+)?/;
    const match = url.match(regExp);
    return match ? match[1] : "";
  };
  const getYouTubeThumbnailUrl = (url: string): string => {
    const videoId = extractYouTubeId(url);
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  // render data
  const renderPersonnalApps = () => {
    const elements: JSX.Element[] = [];
    const limitedApps = TOUserApp.slice(0, 5);
  
    for (const [index, app] of limitedApps.entries()) {
      elements.push(
        <li key={index} className="hoverShadow">
          <a href={app.url} target="_blank">
            {TOUrlApp[index] ? (
              <img src={TOUrlApp[index]} alt={app.name} />
            ) : (
              <ExtensionIcon />
            )}
            <p>{app.name}</p>
          </a>
        </li>
      );
    }
  
    return <ul>{elements}</ul>;
  };
  
  const renderDefaultApps = () => {
    const defaultApps = [
      {
        href: "https://sites.google.com/jeanrouyerautomobiles.fr/base-documentaire/accueil",
        src: base_doc,
        alt: "Base documentaire",
        text: "Base documentaire",
      },
      {
        href: "https://www.jeanrouyerautomobiles.fr/recrutement/recrutement/offres-demploi/",
        target: "_blank",
        src: offres_emploi,
        alt: "Offres d'emploi",
        text: "Offres d'emploi",
      },
      {
        href: "",
        src: handicap,
        alt: "Accompagnement handicap",
        text: "Accompagnement handicap",
      },
      {
        href: "",
        src: e_learning,
        alt: "E-learning",
        text: "E-learning",
      },
      {
        href: "https://drive.google.com/file/d/1fOsx-t-2ns-wa-bFTxVNP38EcrB5YJip/view?usp=drive_link",
        src: charte_ethique,
        alt: "Charte éthique",
        text: "Charte éthique",
      },
      {
        href: "https://drive.google.com/drive/folders/1zmn8RDa-8_0xN5gUNXKMdEj0wJrHGU4k",
        src: livret_accueil,
        alt: "Livret d'accueil",
        text: "Livret d'accueil",
      },
    ];

    return (
      <ul>
        {defaultApps.map((app, index) => (
          <li key={index} className="hoverShadow">
            <a href={app.href} target={app.target || "_self"}>
              <img src={app.src} alt={app.alt} />
              <p className={style.backgroundBlur}>{app.text}</p>
            </a>
          </li>
        ))}
      </ul>
    );
  };

  // posts
  const renderPostForCarousel = (post: Post) => (
    <div className={style.post}>
      <div className={style.postContent} key={post.uid}>
        <div className={style.postBody}>
          {post.urlYoutube ? (
            <div className={style.youtubeBox}>
              <img src={youtubeLogo} className={style.youtubeLogo} />
              <img
                src={getYouTubeThumbnailUrl(post.urlYoutube)}
                alt="YouTube video thumbnail"
              />
            </div>
          ) : (
            <img src={post.photo ? post.photo : noPhotoPost} alt="actualité" />
          )}
          <div className={`${style.postText} ${style.backgroundBlur}`}>
            <div className={style.description}>
              <div>
                <h3>{post.title}</h3>
                {post.subtitle && <h4>{post.subtitle}</h4>}
                {post.description && (
                  <Box
                    className={style.content}
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    {truncateText(post.description, 100)}
                  </Box>
                )}
              </div>
              <div>
                <p className={style.date}>
                  {post.createdAt &&
                    jsDateToLocalFr(new Date(post.createdAt))}
                </p>
                <Link
                  to={`/toutes-actus#post-${post.uid}`}
                  className={`button smallButton ${style.button}`}
                >
                  Voir l'actu
                  <ExitToAppIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const renderPostsCarousel = () => {
    const importantPosts = TOPost.filter(
      (post) =>
        post.postCategoryId === postCategoriesNews && post.isImportantPost
    );

    if (importantPosts.length > 1) {
      return (
        <Carousel
          autoPlay={true}
          interval={8000}
          stopAutoPlayOnHover={true}
          animation="slide"
          duration={1500}
          className={style.carousel}
        >
          {importantPosts.map(renderPostForCarousel)}
        </Carousel>
      );
    } else if (importantPosts.length === 1) {
      const singlePost = importantPosts[0]

      return (
        <div className={`${style.post} ${style.singlePost}`}>
          <div className={style.postContent} key={singlePost.uid}>
            <div className={style.postBody}>
              {singlePost.urlYoutube ? (
                <div className={style.youtubeBox}>
                  <img src={youtubeLogo} className={style.youtubeLogo} />
                  <img
                    src={getYouTubeThumbnailUrl(singlePost.urlYoutube)}
                    alt="YouTube video thumbnail"
                  />
                </div>
              ) : (
                <img src={singlePost.photo ? singlePost.photo : noPhotoPost} alt="actualité" />
              )}
              <div className={`${style.postText} ${style.backgroundBlur}`}>
                <div className={style.description}>
                  <div>
                    <h3>{singlePost.title}</h3>
                    {singlePost.subtitle && <h4>{singlePost.subtitle}</h4>}
                    {singlePost.description && (
                      <Box
                        className={style.content}
                        sx={{ whiteSpace: "pre-wrap" }}
                      >
                        {truncateText(singlePost.description, 100)}
                      </Box>
                    )}
                  </div>
                  <div>
                    <p className={style.date}>
                      {singlePost.createdAt &&
                        jsDateToLocalFr(new Date(singlePost.createdAt))}
                    </p>
                    <Link
                      to={`/toutes-actus#post-${singlePost.uid}`}
                      className={`button smallButton ${style.button}`}
                    >
                      Voir l'actu
                      <ExitToAppIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    } else {
      return (
        <div className={style.post}>
          <div className={style.postContent} key="1">
            <div className={style.postBody}>
              <img src={noPhotoPost} alt="actualité" />
              <div className={`${style.postText} ${style.backgroundBlur}`}>
                <div className={style.description}>
                  <h3>Aucune actualité</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const renderPostForList = (post: Post) => (
    <div className={style.post}>
      <div className={style.postContent}>
        <div className={style.header}>
          <div className={style.tagsBox}>
            <span className={style.date}>
              {post.createdAt && jsDateToLocalFr(new Date(post.createdAt))}
            </span>
          </div>
        </div>
        <div className={style.postBody}>
          {post.urlYoutube ? (
            <div className={style.youtubeBox}>
              <img src={youtubeLogo} className={style.youtubeLogo} />
              <img
                src={getYouTubeThumbnailUrl(post.urlYoutube)}
                alt="YouTube video thumbnail"
              />
            </div>
          ) : (
            <img
              src={post.photo ? post.photo : noPhotoPost}
              alt="actualité"
            />
          )}
          <div className={`${style.postText} ${style.backgroundBlur}`}>
            <h3>{post.title}</h3>
            {post.subtitle && <h4>{post.subtitle}</h4>}
            {post.description && (
              <p className={style.content}>
                {truncateText(post.description, 270)}
              </p>
            )}
            <Link
              to={`/toutes-actus#post-${post.uid}`}
              className={`button smallButton ${style.button}`}
            >
              Voir l'actu
              <ExitToAppIcon />
            </Link>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
  const renderPostsList = () => {
    const T_newsPosts = TOPost.filter(
      (post) =>
        post.postCategoryId === postCategoriesNews &&
        post.isImportantPost === false
    );

    if (T_newsPosts.length > 0) {
      return (
        <div className={style.postsList}>
          {T_newsPosts.map(renderPostForList)}
        </div>
      );
    } else {
      return <p>Aucune actualité</p>;
    }
  };

  // new employees
  const onCloseNewEmployeeModal = async () => {
    setIsNewEmployeeModalOpen(false);
  };
  const onNewEmployeeClick = async (postUid: string) => {
    await fetchNewEmployeeDetails(postUid);
    setIsNewEmployeeModalOpen(true);
  };
  const fetchNewEmployeeDetails = async (newEmployeeUid: string) => {
    let response;

    setNewEmployeeData({
      uid: "",
      title: "",
      subtitle: "",
      description: "",
      url: "",
      urlYoutube: "",
      photo: "",
      newEmployeeJob: "",
      univers: {
        uid: "",
        name: ""
      },
      salepoint: {
        uid: "",
        name: ""
      },
      postCategoryId: 0,
      isImportantPost: false,
      likeNumber: 0,
      isDraft: false,
      isSuggestedPost: false,
      createdBy: null,
      date: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    });

    try {
      response = await Api.get("post/", "uid=" + newEmployeeUid);
      console.log("Post récupéré avec succès", response);
      setNewEmployeeData(response?.data);
    } catch (error) {
      console.error("Erreur lors de la récupération du post", error);
    }
  };
  const renderNewEmployeeForList = (post: Post) => (
    <div
      className={`${style.newEmployee} hoverShadow`}
      onClick={() => onNewEmployeeClick(post.uid)}
    >
      <img
        src={post.photo ? post.photo : noPhotoNewEmployee}
        alt="nouveau collaborateur"
      />
      <p className={style.backgroundBlur}>
        {post.title} {post.subtitle}
      </p>
    </div>
  );
  const renderNewEmployeeList = () => {
    const newEmployeePosts = TOPost.filter(
      (post) => post.postCategoryId === postCategoriesNewEmployee
    );

    return (
      <div className={style.list}>
        {newEmployeePosts.map((post) => renderNewEmployeeForList(post))}
      </div>
    );
  };

  return loader ? (
    <Loader />
  ) : (
    <div id={style.homePage} className="background">
      <div className={style.content}>
        <div id={style.columnOne} className={style.column}>
          <div id={style.postsCarousel} className={style.block}>
            {renderPostsCarousel()}
          </div>
          <div id={style.appsBlock}>
            <div id={style.userApps} className={`${style.apps} ${style.block}`}>
              <h2>Mes outils</h2>
              {renderPersonnalApps()}
            </div>
            <div id={style.docJra} className={`${style.apps} ${style.block}`}>
              <h2>
                Ma documentation JRA <OpenInNewIcon />
              </h2>
              {renderDefaultApps()}
            </div>
          </div>
        </div>

        <div id={style.columnTwo} className={style.column}>
          <div
            id={style.postsList}
            className={`${style.block} ${style.firstBlock}`}
          >
            <div className={style.blockHeader}>
              <h2>
                <span>Les nouvelles</span>
                <span className={style.postCategory}>actualités</span>
              </h2>
              <div className={style.buttonBox}>
                <Link to="/accueilConfig" className={`button ${style.button}`}>
                  Gérer les actualités
                  <ExitToAppIcon />
                </Link>
                <Link to="/toutes-actus" className={`button ${style.button}`}>
                  Toutes les actualités
                  <ExitToAppIcon />
                </Link>
              </div>
            </div>
            <div className={`autoScroll ${style.autoScroll}`}>
              <div id={style.stats}>
                <p className={style.statsTitle}>Les chiffres de 2024</p>
                <div className={style.statsBox}>
                  <div className={style.block}>
                    <p className={style.number}>7AT vs 9iso</p>
                    <p className={style.title}>
                      Sécurité
                      <Tooltip title="Poursuivre la baisse de nos accidents en 2024 (Obj 20).">
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </p>
                  </div>

                  <div className={style.block}>
                    <p className={style.number}>4.38/5</p>
                    <p className={style.title}>
                      Qualité
                      <Tooltip title="Top 3 Note Google > 4.3 pour chaque marque.">
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </p>
                  </div>

                  <div className={style.block}>
                    <p className={style.number}>?</p>
                    <p className={style.title}>
                      Part de marché
                      <Tooltip title=">= Moyenne France pour chaque marque.">
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </p>
                  </div>

                  <div className={style.block}>
                    <p className={style.number}>0.2%</p>
                    <p className={style.title}>
                      Rentabilité
                      <Tooltip title="100% des affaires profitables.">
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
              {renderPostsList()}
            </div>
          </div>

          <div id={style.newEmployeelist} className={style.block}>
            <h2>
              <span>Les nouveaux</span>
              <span className={style.postCategory}>collaborateurs</span>
            </h2>
            {renderNewEmployeeList()}
          </div>
        </div>
      </div>

      <NewEmployeeModal
        isOpen={isNewEmployeeModalOpen}
        onClose={onCloseNewEmployeeModal}
        newEmployeeData={newEmployeeData}
      />
    </div>
  );
}

export default Accueil;
