import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/Check';
import style from './stepper.module.scss'
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import ExtensionIcon from '@mui/icons-material/Extension';
import { App } from "../../services/object/App";
import useMediaQuery from '@mui/material/useMediaQuery';

interface StepperLinearProps {
  activeStep?: { index: number, appUid: string },
  setActiveStep: React.Dispatch<React.SetStateAction<{ index: number, appUid: string }| undefined>>,
  data: App[];
  handleSaveStepper: () => Promise<boolean>,
  handleNextStepper: () => Promise<boolean>,
  children?: React.ReactNode;
}

const StepperLinear: React.FC<StepperLinearProps> = ({ data, activeStep = { index: 0, appUid: "" }, setActiveStep, handleSaveStepper, handleNextStepper, children }) => {

  const [icon, setIcon] = useState<(React.ReactNode | null)[]>([]);

  useEffect(() => {
      const fetchIcons = async () => {
          const newIcons: (React.ReactNode | null)[] = [];
          for (const item of data) {
            const icon = await CustomStepIcon({ icon: '', url: item.url ?? '' });
              newIcons.push(icon);
          }
          setIcon(newIcons);
      };
      fetchIcons();
  }, [data]);

  const handleBack = () => {
    setActiveStep({ index:  activeStep.index - 1, appUid: data[activeStep.index - 1].uid });
  };
  
  const handleNext = async (e : React.MouseEvent<HTMLButtonElement>  ) => {
    e.preventDefault();
    const isStepperSaved = await handleSaveStepper();
    if (isStepperSaved) {
      setActiveStep({ index:  activeStep.index + 1, appUid: data[activeStep.index + 1].uid });
    }
  };

  const handleFinish = async (e : React.MouseEvent<HTMLButtonElement>  ) => {
    e.preventDefault();
    await handleSaveStepper();
    setActiveStep({ index: 0, appUid: data[0].uid });
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 23,
      
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,#0a9fda 0%,#008EC8 50%,#006d98 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,#0a9fda 0%,#008EC8 50%,#006d98 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      background:'#fff',
      borderRadius:'50%',
      width: 50,
      height: 50,
      border:'3px solid #008EC8',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      background:'#fff',
      borderRadius:'50%',
      width: 50,
      height: 50,
      border:'3px solid #008EC8',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    '& img': {
      maxWidth: '60%',
      maxHeight: '60%',
    },
    '& div': {
      fontSize: '10px'
    },
  }));


  function fetchIcon(url: string, active: boolean, completed: boolean): React.ReactNode {
    try {
      if (url) {
        return <img src={url} alt='' 
        style={{ filter: (active || completed) ? 'none' : 'grayscale(100%)' }} 
        onError={(e) => {
          e.currentTarget.style.display = 'none';
          console.error('Error loading favicon:', url);
          const parent = e.currentTarget.parentNode as HTMLElement | null;
          if (parent) {
            const noIconElement = document.createElement('div');
            ReactDOM.render(<ExtensionIcon htmlColor="#008EC8" style={{ filter: (active || completed) ? 'none' : 'grayscale(100%)' }}/>, noIconElement);
            parent.appendChild(noIconElement); 
          }
        }}
        />;
      } else {
        return <div><ExtensionIcon htmlColor="#008EC8" style={{ filter: (active || completed) ? 'none' : 'grayscale(100%)' }}/></div>;
      }
    } catch (error) {
      console.error('Error fetching favicon:', error);
      return <div>error</div>;
    }
  }
  
  function CustomStepIcon(props: StepIconProps & { url: string }) {
    const { active, completed, className, url} = props;
    const icon = fetchIcon(url, !!active, !!completed);
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icon}
      </ColorlibStepIconRoot>
    );
  }

  const isSmallScreen = useMediaQuery('(max-width: 666px)');

  const scrollToStep = (stepIndex: number) => {
    const stepElement = document.getElementById(`step-${stepIndex}`);
    if (stepElement) {
      stepElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };


  return (
    <>
    {!isSmallScreen && (
    <Box sx={{ width: '100%', marginTop: '2rem' }}>
      <Stepper  activeStep={activeStep.index} alternativeLabel connector={<ColorlibConnector />}>
        {data && data.map((item: App, index: number) => {
            return (
              <Step key={index}>
                <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} url={item.url ?? ''}  />} >{item.name}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
      {children}
           
      <div className={(data.length > 1 )  ? (style.container
                                            + " " + ((activeStep.index === data.length -1 ) ? style.containerLeft : "" )
                                            + " " + ((activeStep.index !== data.length - 1 && activeStep.index !== 0) ? style.containerFull : "")) 
                                          : ""}>
        {
          (activeStep.index !== 0) ?
            <Button className={style.buttonNS} variant="contained" onClick={handleBack}><NavigateBeforeIcon/>
              <span> Précédent</span>
            </Button>
          :
            ""
        }
        {
          (activeStep.index !== data.length - 1) ?
            <Button className={style.buttonNS} variant="contained" type="submit" onClick={handleNext}>
              <span>Suivant </span><NavigateNextIcon/>
            </Button>
          :
            ""
        }
        
        
      </div>
    </Box>
  )}
  {isSmallScreen && (
    <Box sx={{ maxWidth: 480, marginTop: '2rem' }}>
    <Stepper activeStep={activeStep.index}  orientation="vertical">
      {data && data.map((item: App, index: number) => {
        return (
          <Step key={index}>
          <StepLabel id={`step-${index}`} StepIconComponent={(props) => <CustomStepIcon {...props} url={item.url ?? ''}  />}>{item.name}</StepLabel>
          <StepContent 
              TransitionProps={{
                addEndListener: (node, done) => {
                  const handleTransitionEnd = () => {
                    scrollToStep(activeStep.index);
                    done();
                    node.removeEventListener('transitionend', handleTransitionEnd, false);
                  };
                  node.addEventListener('transitionend', handleTransitionEnd, false);
                }
              }}
          >
            {children}
          </StepContent>
        </Step>
      );
      })}
    </Stepper>
    
       <div className={[style.buttonDiv2, (activeStep.index !== data.length - 1 ? '' : style.unvisibility)].join(' ')}>
        {activeStep.index !== data.length - 1 ? (
          <Button className={style.buttonNS} variant="contained" type="submit" onClick={handleNext}><span>Suivant </span><NavigateNextIcon/></Button>
        ) : (
          <Button className={style.buttonNS} variant="contained" type="submit" onClick={handleFinish} endIcon={<CheckIcon/>}><span>Terminer </span></Button>
        )}
      </div>
      
      <div className={[style.buttonDiv1, style.buttonBackStep, (activeStep.index !== 0 ? '' : style.unvisibility)].join(' ')}>
          <Button className={style.buttonP} variant="contained" onClick={handleBack}><NavigateBeforeIcon/><span> Précédent</span></Button>
      </div>    

      
    
  </Box>
  )}
  </>
  );
};

export default StepperLinear;