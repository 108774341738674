import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import style from './assets/style/profile.module.scss';

import { App } from '../../services/object/App';
import { Service_Api } from "../../services/Api";
import { useAuth } from "../../services/auth/useAuth";

import Notification, {
  NotificationProps,
  renderNotif,
} from "../../components/notification/Notification";
import Alert, { AlertProps } from "../../components/alert/Alert";
import ModalFormComponent from "../../components/modal/ModalFormComponent";

import ModalSuggestedApp from "./ModalSuggestedApp";

import DragAndDropIcon from "./assets/img/dragAndDropIcon.png"

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExtensionIcon from '@mui/icons-material/Extension';

const reorder = (
  list: App[],
  startIndex: number,
  endIndex: number
): App[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function ChangeApps() {
  const Api = Service_Api();
  const { user } = useAuth();
  const [userApps, setUserApps] = useState<App[]>([]);
  const [faviconUrls, setFaviconUrls] = useState<string[]>([]);
  const [loadingFavicons, setLoadingFavicons] = useState<boolean>(false)
  const [notif, setNotif] = useState<NotificationProps>();
  const [alert, setAlert] = useState<AlertProps>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const refAppForm =
    useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const [appForm, setAppForm] = useState<App | undefined>();
  const [modalAppComponent, setModalAppComponent] = useState<JSX.Element[]>();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  useEffect(() => {
    fetchAppsByUserUid();
  }, []);
  useEffect(() => {
    let children = ModalSuggestedApp({
      errorMessages,
      handleAppChange,
      appForm,
      refAppForm,
    });
    setModalAppComponent(children);
  }, [appForm, errorMessages]);

  // fetch
  const fetchAppsByUserUid = async () => {
    try {
      const response = await Api.get(
        "app/getAppsOrderByUserUid",
        "userUid=" + user.uid
      );
      const T_userApps: App[] = response?.data;

      setUserApps(T_userApps);
      fetchFaviconsForApps(T_userApps);
    } catch (error) {
      console.error("Erreur lors de la récupération des données");
    }
  };
  const fetchFaviconsForApps = async (apps: App[]) => {
    try {
      setLoadingFavicons(true);
      const favicons = await Promise.all(apps.map(async (app) => {
        const faviconUrl = await fetchFavicon(app);
        return faviconUrl;
      }));
      setFaviconUrls(favicons);
    } catch (error) {
      console.error("Erreur lors de la récupération des favicons", error);
    } finally {
      setLoadingFavicons(false);
    }
  };
  const fetchFavicon = async (app: App): Promise<string> => {
    let faviconUrl = '';
    const url = app.url;
    try {
      const response = await fetch(url);
      const html = await response.text();
      const iconMatch = html.match(
        /<link\s+[^>]*rel=["'](?:shortcut icon|icon)["'][^>]*href=["']([^"']+)["']/i
      );
  
      if (iconMatch && iconMatch[1]) {
        const iconUrl = new URL(iconMatch[1], url).href;
  
        try {
          const iconResponse = await fetch(iconUrl, { method: "HEAD" });
          if (iconUrl.includes("callcenter")) {
            console.log('iconResponse:', iconResponse);
          }
  
          if (iconResponse.ok) {
            faviconUrl = iconUrl;
          } else {
            faviconUrl = "";  // URL inaccessible
          }
        } catch (iconError) {
          console.error("Error fetching icon:", iconError);
          faviconUrl = "";  // Erreur d'accès à l'icône
        }
      } else {
        faviconUrl = "";  // Pas d'icône trouvée
      }
    } catch (error) {
      console.error("Error fetching favicon:", error);
      faviconUrl = "";
    }
    return faviconUrl;
  };
  

  // actions 
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const reorderedApps = reorder(
      userApps,
      result.source.index,
      result.destination.index
    );

    setUserApps(reorderedApps);
    fetchFaviconsForApps(reorderedApps);
  };
  const onOpenModalClick = () => {
    let formData = {
      uid: "",
      name: "",
      url: "",
      isJraApp: false
    };

    setAppForm(formData);
    setIsModalOpen(true);
  };
  const onCloseModalClick = async () => {
    setAppForm({
      uid: "",
      name: "",
      url: "",
      isJraApp: false
    });

    setIsModalOpen(false);
    setErrorMessages({});
  };
  const onSaveClick = async () => {
    try {
      const response = await Api.post("app/updateAppsOrderByUserUid", {
        userUid: user.uid,
        appsOrder: userApps.map((app) => app.uid),
      });
      setNotif({
        open: true,
        contentText: "Les outils ont été mis à jour avec succès!",
        severity: "success",
        handleClose: () => setNotif(undefined),
      });
    } catch (error) {
      setNotif({
        open: true,
        contentText:
          "Une erreur est survenue lors de la mise à jour des outils.",
        severity: "error",
        handleClose: () => setNotif(undefined),
      });
    }
  };
  const onSuggestedAppSaveClick = async () => {
    if (handleFormErrors()) {
      try {
        console.log(appForm);
        await handleSuggestedPost();
      } catch (error) {
        console.error("Erreur lors de la soumission du post", error);
      }
      return true;
    } else {
      return false;
    }
  };

  // Process datas after actions
  const handleAppChange = (
    property: string,
    value: string
  ) => {
    console.log(property, value);

    setAppForm((prevappForms: any) => ({
      ...prevappForms,
      [property]: value,
    }));
  };
  const handleSuggestedPost = async () => {
    setAlert({
      open: true,
      title: "Soumettre",
      contentText:
        "Êtes-vous sûr de vouloir soumettre cet outil ? La proposition sera envoyée à un administrateur pour validation.",
      contentButtonSuccess: "Oui",
      contentButtonAbord: "Non",
      onSubmitSuccess: async () => {
        try {
          if (appForm) {
            const actualAppForm = {
              ...appForm,
            };
            const response = await Api.post("app/suggest", { T_data: actualAppForm });
            console.log("Formulaire soumis avec succès", response);
            onCloseModalClick();
            setNotif(renderNotif(true, "suggest", handleCloseNotif));
          }
        } catch (error) {
          console.error("Erreur lors de la soumission du formulaire");
        } finally {
          setAlert(undefined);
        }
      },
      onSubmitAbord: () => setAlert(undefined),
    });
  };
  const handleFormErrors = () => {
    const errors: Record<string, string> = {};

    const requiredString = "Requis";
    const invalidFormatString = "Format invalide";
    const lengthExceededString = "Longueur maximale dépassée";
    const noEmoticonsAllowedString = "Les émoticônes ne sont pas autorisées";

    const adjustAndValidateUrl = (url: string): string | null => {
      if (!url) return null;

      if (!/^https?:\/\//i.test(url)) {
        url = "https://" + url;
      }

      try {
        const parsedUrl = new URL(url);
        if (parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:") {
          return url;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    };

    const hasNoEmoticons = (text: string): boolean => {
      if (!text) return true;
      const regex = /[\p{Emoji_Presentation}|\p{Extended_Pictographic}]/u;

      return !regex.test(text);
    };

    if (appForm) {
      if (!appForm.name) {
        errors.name = requiredString;
      } else {
        if (appForm.name.length > 100) {
          errors.name = lengthExceededString;
        }
        if (!hasNoEmoticons(appForm.name)) {
          errors.name = noEmoticonsAllowedString;
        }
      }

      let url = adjustAndValidateUrl(appForm.url);
      if (!url) {
        errors.url = invalidFormatString;
      } else {
        appForm.url = url
      }

      setErrorMessages(errors);
    }
    return Object.keys(errors).length === 0;
  };
  const handleCloseNotif = () => {
    setNotif(undefined);
  };

  return (
    <Box
      id={style.editProfileApps}
      className={`${style.box} ${loadingFavicons ? style.loading : ''}`}
    >
      {loadingFavicons && <div className={style.spinner}></div>}
      <div className={style.header}>
        <h2>Trier mes outils</h2>
        <div className={style.dragAndDropIcon}>
          <p>Organisez vos 5 outils préférés <br/> sur la page d'accueil</p>
          <img src={DragAndDropIcon} alt="glisser déposer" />
        </div>
      </div>
      <div className={style.content}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={`${style.draggableGrid} ${snapshot.isDraggingOver ? style.dragging : ''}`}
                {...provided.droppableProps}
              >
                {/* Apps apparaissant sur l'accueil */}
                <div className={style.blueBorderContainer}>
                  {userApps.slice(0, 5).map((app, index) => (
                    <Draggable key={app.uid} draggableId={app.uid} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${style.draggableItem} ${snapshot.isDragging ? style.dragging : ''} hoverShadow`}
                        >
                          {faviconUrls[index] ? (
                            <img src={faviconUrls[index]} alt={app.name} />
                          ) : (
                            <ExtensionIcon />
                          )}
                          <p>{app.name}</p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
  
                {/* Les autres apps */}
                {userApps.slice(5).map((app, index) => (
                  <Draggable key={app.uid} draggableId={app.uid} index={index + 5}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${style.draggableItem} ${snapshot.isDragging ? style.dragging : ''} hoverShadow`}
                      >
                        {faviconUrls[index + 5] ? (
                          <img src={faviconUrls[index + 5]} alt={app.name} />
                        ) : (
                          <ExtensionIcon />
                        )}
                        <p>{app.name}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={style.buttonProfile}>
        <Button variant="contained" onClick={onOpenModalClick}>
          Proposer un outil
        </Button>
        <Button variant="contained" onClick={onSaveClick}>
          Enregistrer
        </Button>
      </div>
  
      {notif?.open === true ? <Notification {...notif} /> : ""}
      {alert?.open === true ? <Alert {...alert} /> : ""}
  
      {
        <ModalFormComponent
          title="Proposer un outil"
          modalOpen={isModalOpen}
          onClose={onCloseModalClick}
          onFormSubmitSuccess={onSuggestedAppSaveClick}
          childrenForm={modalAppComponent}
          formEvent={[onSuggestedAppSaveClick]}
          setErrorMessages={setErrorMessages}
          modalType="postSuggestion"
        />
      }
    </Box>
  );
  
}

export default ChangeApps;
