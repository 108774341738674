import styleModal from '../../components/tinyModal/tinyModal.module.scss';
import { Button, FormControl, FormHelperText, Input, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Salepoint } from '../../services/object/Salepoint';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

interface SalepointTinyPopupChildProps {
    handleButtonGoogleEvent: (event: string) => void
    handleClassicButtonEvent: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
const SalepointTinyChildPopup = ({handleButtonGoogleEvent,  handleClassicButtonEvent} :SalepointTinyPopupChildProps) => {
        let gmbId = "";
        const setGMBId = (value :string) => {
            gmbId = value
        };
        const getGMBId = () => {
            return gmbId;
        };
    return (
        <>
            <div className={styleModal.items}>
                <h2>Une fiche GoogleMyBusiness existe et vous êtes administrateur de la fiche :</h2>
                <FormControl sx={{ m: 1, width:'70%' }}>
                    <OutlinedInput
                        id="outlined-adornment-gmbId"
                        name="gmbId"
                        onChange={(e) => setGMBId(e.target.value)}
                        label="Identifiant GMB de la concession"
                        type='number' 
                        required
                    />
                    <InputLabel htmlFor="outlined-adornment-gmbId">Identifiant GMB de la concession</InputLabel>
                    <FormHelperText id="gmbId-text"><a href="https://support.google.com/business/thread/161631677?hl=fr&msgid=161866175" target="_blank" rel="noopener noreferrer">Où trouver l'indentifiant ?</a></FormHelperText>
                </FormControl>
                <Button variant="contained"
                        onClick={() => handleButtonGoogleEvent(getGMBId())} 
                        endIcon={<DownloadForOfflineIcon/>}>
                    Importer depuis GMB 
                </Button>
                
            </div>
            <div className={styleModal.items}>
                <h2>Créer une nouvelle concession</h2>
                <Button variant="outlined" onClick={(e) => (handleClassicButtonEvent(e))}
                         endIcon={<AddIcon/>}> 
                    Créer
                </Button>
            </div>
        </>
    )
}
export default SalepointTinyChildPopup;