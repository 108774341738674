import React, { useState, useEffect, ChangeEvent } from "react";

import style from "../styles/accueilConfig.module.scss";

import defaultPhoto from "../../../assets/postPhoto.png";

import { Loader } from "../../../../components/loader/Loader";

import { Service_Api } from "../../../../services/Api";
import { Post } from "../../../../services/object/Post";
import { Salepoint, SalepointData } from "../../../../services/object/Salepoint";
import { getBase64 } from "../../../../services/tools/imgToBase64";
import { dateJstoSqlDate } from '../../../../services/tools/translateDate';

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Button from "@mui/material/Button";
import { SelectChangeEvent } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Autocomplete from '@mui/material/Autocomplete';

import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";


interface FormProps {
  onChange: (property: string, value: string | number | SalepointData | null) => void;
  formPost: Post | undefined;
  errorMessages: Record<string, string>;
  isSuggestionForm?: boolean;
}

const Form = ({
  onChange,
  formPost,
  errorMessages,
  isSuggestionForm = false,
}: FormProps) => {
  const Api = Service_Api();

  const [loader, setLoader] = useState<boolean>(true);
  const [selectedImage, setSelectedImage] = useState<string | null>();
  const MAX_IMAGE_SIZE_MB = 3 * 1024 * 1024;
  const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];
  const [localFormValues, setLocalFormValues] = useState({
    title: formPost?.title ?? "",
    subtitle: formPost?.subtitle ?? "",
    description: formPost?.description ?? "",
    url: formPost?.url ?? "",
    urlYoutube: formPost?.urlYoutube ?? "",
    newEmployeeJob: formPost?.newEmployeeJob ?? "",
    postCategoryId: formPost?.postCategoryId ?? 1,
    isImportantPost: formPost?.isImportantPost ?? 0,
    date: formPost?.date ?? ""
  });
  const isNewEmployeeForm =
    formPost?.postCategoryId === 2 || localFormValues?.postCategoryId === 2;
  const [TOSalepoints, setTOSalepoints] = useState<Salepoint[]>([]);

  useEffect(() => {
    if (formPost) {
      setLocalFormValues({
        title: formPost.title ?? "",
        subtitle: formPost.subtitle ?? "",
        description: formPost.description ?? "",
        url: formPost.url ?? "",
        urlYoutube: formPost.urlYoutube ?? "",
        newEmployeeJob: formPost.newEmployeeJob ?? "",
        postCategoryId: formPost.postCategoryId ?? 1,
        isImportantPost: formPost.isImportantPost ?? 0,
        date: formPost.date ?? ""
      });
    }
    fetchAllSalepoints()
    setTimeout(function () {
      setLoader(false);
    }, 500);
  }, [formPost]);
  const fetchAllSalepoints = async () => {
    try {
      const response = await Api.get("Salepoint/");
      setTOSalepoints(response?.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données");
    }
  };
  const allSalepointsGrouped = TOSalepoints.map((option) => {
    const firstWord = option.name.split(' ')[0];
    return {
      firstWord: firstWord,
      ...option,
    };
  });
  const selectedSalepoint = allSalepointsGrouped.find(
    (salepoint) => salepoint.uid === formPost?.salepoint?.uid
  );

  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange(name, value);
  };
  const handleAutocompleteChange = (
    event: React.SyntheticEvent,
    value: { uid: string; name: string; address: string; city: string; gmbId: string; gmbName: string; siret: string; companyName: string; zip: string; photo: string; firstWord: string } | null
  ) => {
    onChange("salepoint", value ? { uid: value.uid, name: value.name } : '');
  };
  const handleDatePickerChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      onChange("date", newValue.format('YYYY-MM-DD'));
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type && ALLOWED_IMAGE_TYPES.includes(file.type)) {
        if (file.size > MAX_IMAGE_SIZE_MB) {
          alert(
            "L'image sélectionnée dépasse la taille maximale autorisée de 3 Mo."
          );
          return;
        }
        setSelectedImage(URL.createObjectURL(file));
        getBase64(file, (result: string) => {
          onChange("photo", result);
        });
      } else {
        alert("Veuillez choisir une image de type [.jpeg] ou [.png].");
      }
    } else {
      setSelectedImage(null);
    }
  };
  const handleCircleClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleSelectChange = (event: SelectChangeEvent<string | number>) => {
    const { name, value } = event.target;
    let parsedValue: string | number = value;

    if (name === "isImportantPost") {
      parsedValue = Number(value);
    }

    onChange(name, parsedValue);
  };

  return loader ? (
    <Loader position={"form"} />
  ) : (
    <div className={`${style.column} ${style.content}`}>
      {!isSuggestionForm ? (
        <>
          <FormControl
            className={`${style.input} ${style.selectIsImportantPost}`}
            fullWidth
            size="small"
          >
            <InputLabel>Catégorie *</InputLabel>
            <Select
              value={
                formPost?.postCategoryId ? formPost?.postCategoryId : 1
              }
              label="Catégorie"
              onChange={handleSelectChange}
              required
              name="postCategoryId"
            >
              <MenuItem value={1}>Actualité</MenuItem>
              <MenuItem value={2}>Nouveau collaborateur</MenuItem>
            </Select>
          </FormControl>

          {isNewEmployeeForm ? null : (
            <FormControl
              className={`${style.input} ${style.selectIsImportantPost}`}
              fullWidth
              size="small"
            >
              <InputLabel>Actualité importante *</InputLabel>
              <Select
                value={formPost?.isImportantPost ? 1 : 0}
                label="Actualité importante"
                onChange={handleSelectChange}
                required
                name="isImportantPost"
              >
                <MenuItem value={0}>Non</MenuItem>
                <MenuItem value={1}>Oui</MenuItem>
              </Select>
            </FormControl>
          )}
        </>
      ) : null}
      <div className={style.row}>
        <div className={`${style.column} ${style.firstBox}`}>
          <TextField
            fullWidth
            label={isNewEmployeeForm ? "Prénom NOM" : "Titre"}
            size="small"
            name="title"
            value={localFormValues.title}
            onChange={handleLocalChange}
            onBlur={handleBlur}
            required
            error={!!errorMessages.title}
            helperText={errorMessages.title}
            className={style.input}
          />
          {isNewEmployeeForm ? null : (
            <>
              <TextField
                fullWidth
                label="Sous-titre"
                size="small"
                name="subtitle"
                value={localFormValues.subtitle}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                className={style.input}
                error={!!errorMessages.subtitle}
                helperText={errorMessages.subtitle}
              />
            </>
          )}
          <TextField
            fullWidth
            multiline
            rows={isNewEmployeeForm ? 10 : 5}
            label="Description"
            size="small"
            name="description"
            value={localFormValues.description}
            onChange={handleLocalChange}
            onBlur={handleBlur}
            className={style.input}
            required
            error={!!errorMessages.description}
            helperText={errorMessages.description}
          />
          {!isNewEmployeeForm ? (
            <>
              <TextField
                fullWidth
                label="Url Youtube"
                size="small"
                name="urlYoutube"
                value={localFormValues.urlYoutube}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                className={style.input}
                error={!!errorMessages.urlYoutube}
                helperText={errorMessages.urlYoutube}
              />
              <TextField
                fullWidth
                label={'Url "plus d\'infos"'}
                size="small"
                name="url"
                value={localFormValues.url}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                className={style.input}
                error={!!errorMessages.url}
                helperText={errorMessages.url}
              />
            </>
          ) : (
            <TextField
              fullWidth
              label="Métier"
              size="small"
              name="newEmployeeJob"
              value={localFormValues.newEmployeeJob}
              onChange={handleLocalChange}
              onBlur={handleBlur}
              className={style.input}
              required={isNewEmployeeForm}
              error={isNewEmployeeForm && !!errorMessages.newEmployeeJob}
              helperText={isNewEmployeeForm && errorMessages.newEmployeeJob}
            />
          )}
        </div>
        <div className={`${style.column} ${style.secondBox}`}>
          <div className={style.photoPostBox}>
            <div
              className={`${style.photoPost} ${selectedImage ? style.active : ""
                } ${isNewEmployeeForm && !!errorMessages.photo
                  ? style.errorInput
                  : ""
                }`}
              onClick={handleCircleClick}
              style={{
                backgroundImage: (
                  selectedImage ? `url(${selectedImage})` : formPost?.photo
                )
                  ? `url(${formPost?.photo})`
                  : `url(${defaultPhoto})`,
              }}
            >
              <CreateOutlinedIcon />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              id="fileInput"
              name="photo"
              required={isNewEmployeeForm}
            />
            {isNewEmployeeForm && errorMessages.photo && (
              <p className={style.errorMessage}>{errorMessages.photo}</p>
            )}
            <div className={style.buttonBox}>
              <Button
                className={`${style.buttonCompressImg} ${selectedImage ? style.hidden : ""
                  }`}
                variant="outlined"
                href="https://www.iloveimg.com/fr/compresser-image"
                target="_blank"
              >
                Compresser une image
                <OpenInNewIcon />
              </Button>
              <Tooltip
                title={
                  <Typography fontSize={12}>
                    Ce site web vous permet de reduire la taille de l'image
                    avant de l'uploader. <br /> Si après cela, le poids de
                    l'image dépasse toujours 3Mo, il est possible de la
                    redimensionner d'avantage sur Paint ou sur internet.
                  </Typography>
                }
                placement="top-start"
              >
                <QuestionMarkIcon className={style.questionMark} />
              </Tooltip>
            </div>
          </div>
          {isNewEmployeeForm ? (
            <>
              <Autocomplete
                options={allSalepointsGrouped.sort((a, b) => -b.firstWord.localeCompare(a.firstWord))}
                groupBy={(option) => option.firstWord}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Concession"
                    error={!!errorMessages.salepoint}
                    helperText={errorMessages.salepoint}
                  />
                )}
                size="small"
                onChange={handleAutocompleteChange}
                value={selectedSalepoint || null}
              />
            </>
          ) : null}

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="fr"
            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <DatePicker 
              value={formPost?.date ? dayjs(formPost.date) : dayjs()}
              label="Date"
              onChange={handleDatePickerChange}
              className={style.date}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>

        </div>
      </div>
    </div>
  );
};

export default Form;
