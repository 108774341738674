import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface AlertProps {
    open : boolean,
    title : string|undefined,
    contentText : string|undefined,
    contentButtonSuccess : string|undefined,
    contentButtonAbord : string|undefined,
    onSubmitSuccess:  () => void|Promise<void>|undefined,
    onSubmitAbord:  () => void|Promise<void>|undefined
}

export default function Alert({ open,
                                title,
                                contentText,
                                contentButtonSuccess,
                                contentButtonAbord,
                                onSubmitSuccess,
                                onSubmitAbord} : AlertProps) {

  return (
        <>
            <Dialog
                open={open}
                onClose={onSubmitAbord}
            >
                <DialogTitle id="alert-dialog-title">
                    { title }
                </DialogTitle>
                <DialogContent>
                        {
                            (contentText) ? 
                            contentText.split('\\\\n').map((line, index) => (
                                <DialogContentText id="alert-dialog-description" >
                                    {line}
                                </DialogContentText>
                            ))
                            : ""
                        }
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={onSubmitAbord}>
                        {contentButtonAbord}
                    </Button>
                    <Button onClick={onSubmitSuccess} autoFocus>
                        {contentButtonSuccess}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
  );
}