
import autobonplanLogo from "../../assets/universLogos/Autobonplan.png";
import renaultLogo from "../../assets/universLogos/Renault.png";
import volkswagenLogo from "../../assets/universLogos/Volkswagen.png";
import italAutoLogo from "../../assets/universLogos/ItalAuto.png";
import europcarLogo from "../../assets/universLogos/Europcar.png";
import nissanLogo from "../../assets/universLogos/Nissan.png";
import locotoLogo from "../../assets/universLogos/Locoto.png";
import sofisaLogo from "../../assets/universLogos/Sofisa.png";
export interface Univers {
  uid:string,
  name: string
}

export const UniversLogos: Record<string, string> = {
  "628655c7471319.39063576": autobonplanLogo,
  "628655c74713e5.30075006": renaultLogo,
  "629f8ef05094b3.94661901": volkswagenLogo,
  "629f8ef54c4464.61274682": italAutoLogo,
  "629f8efd4847b7.43252851": europcarLogo,
  "62a0583d813b32.80083472": nissanLogo,
  "63074c52f34510.31887401": locotoLogo,
  "6694edbb9cc7d2.88887839": sofisaLogo,
};

