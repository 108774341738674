import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, MuiEvent, GridToolbar, frFR } from '@mui/x-data-grid';
import { Button, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import './Table.scss'


interface TableChartProps {
  data: any;
  onRowClick: (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => void;
  onCardClick: (salepointUid: string) => void;
  handleDeleteRow: (itemId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  columns: GridColDef<any>[]
}

function TableChart({ data, columns, onCardClick, onRowClick, handleDeleteRow }: TableChartProps) {
  const [searchInput, setSearchInput] = useState("");
  const isMobile = useMediaQuery('(max-width: 632px)');

  const hasDateColumn = columns.some((col) => col.field === 'date');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const filteredData = data.filter((item: any) =>{
    return searchInput.length === 0 ||
    Object.values(item).some((value: any) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )}
  );

  const truncateText = (text: string | string[], maxLength: number): string => {
    if (!text) {
      return '';
    }  
    const str = Array.isArray(text) ? text.join(', ') : text;
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.substring(0, maxLength) + '...';
    }
  };

  return (
    <>
      {isMobile ? (
                <div>
                <TextField
                label="Rechercher"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleChange}
                value={searchInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ zIndex: 0 }}
              />
                {filteredData.map((item: any) => (
                  <Card key={item.id} variant="outlined" sx={{ marginBlock: '12px', position: 'relative' }}>
                    <CardContent>
                      {Object.keys(item).map((key) => (
                        
                        key !== "id" ? (  
                          (key === "statut") ?
                            <ListItem key={key} className="card-item">
                              <span>{columns.find((col) => col.field === key)?.headerName || key}:&nbsp;{<Chip label={item[key].statusText} color={item[key].chipColor} variant="outlined" sx={{width: '90px'}} />}</span>
                            </ListItem>  
                           :                          
                            <ListItem key={key} className="card-item">
                              <span>
                                {columns.find((col) => col.field === key)?.headerName || key}:&nbsp;
                                {key === "concession" ? truncateText(item[key], 100) : ` ${item[key]}`}
                              </span>
                            </ListItem>
                        ) : ""

                      ))}
                      <div className='editdelete'>
                      <IconButton type="submit" onClick={(e) => { e.stopPropagation(); onCardClick(item.id); }}>
                        <EditIcon fontSize='small' color="primary" />
                      </IconButton>
                      <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteRow(item.id, e); }}>
                        <DeleteIcon fontSize='small' color="primary" />
                      </IconButton>
                      </div>
                    </CardContent>
                  </Card>
                ))}
        </div>
      ) : (
        // si ce n'est pas mobile
        <DataGrid
          sx={{ zIndex: '0' }}
          rowHeight={40}
          rows={data}
          columns={columns}
          getRowId={(row) => row.id}
          onRowClick={onRowClick}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          slots={{
            toolbar: GridToolbar,
          }}
          pageSizeOptions={[25, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            sorting: hasDateColumn
              ? {
                  sortModel: [{ field: 'date', sort: 'desc' }],
                }
              : undefined,
          }}
        />
      )}
    </>
  );
}

export default TableChart;