import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Service_Api } from "../../services/Api";
import style from "./assets/style/profile.module.scss";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

import VisibilityOff from "@mui/icons-material/Visibility";
import Visibility from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";

import { TextField, IconButton, InputAdornment } from '@mui/material';

import { useState } from "react";
import Alert, { AlertProps } from "../../components/alert/Alert";
import Notification, {
  NotificationProps,
} from "../../components/notification/Notification";

function PasswordInput({
  label,
  value,
  onChange,
  visible,
  onToggleVisibility,
  onMouseDown,
}: {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  visible: boolean;
  onToggleVisibility: () => void;
  onMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <FormControl variant="outlined" className={style.input}>
      <InputLabel size="small" htmlFor={`outlined-adornment-${label}`}>
        {label}
      </InputLabel>
      <OutlinedInput
        className={style.passwordInput}
        type={visible ? "text" : "password"}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onToggleVisibility}
              onMouseDown={onMouseDown}
              edge="end"
            >
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
}

function ChangePassword() {
  const Api = Service_Api();

  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [displayNotif, setDisplayNotif] = useState<NotificationProps>();

  const handleClickShowPassword = (field: keyof typeof passwordVisibility) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const updateUserPassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    try {
      const data = { old_password: oldPassword, new_password: newPassword };
      await Api.put("user/updatePasswordOldNew", data);
      console.log("Mot de passe mis à jour avec succès !");
    } catch (error: any) {
      console.error(
        "Erreur lors de la mise à jour du mot de passe :",
        error.message
      );
      throw new Error("Échec de la mise à jour du mot de passe.");
    }
  };

  const handleSave = async () => {
    setDisplayAlert({
      open: true,
      title: "Confirmation",
      contentText: "Êtes-vous sûr de vouloir modifier votre mot de passe ?",
      contentButtonSuccess: "Oui",
      contentButtonAbord: "Non",
      onSubmitSuccess: async () => {
        try {
          if (newPassword !== confirmPassword) {
            throw new Error("Les mots de passe ne correspondent pas.");
          }
          await updateUserPassword(oldPassword, newPassword);
          console.log("Mot de passe mis à jour avec succès !");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setDisplayNotif({
            open: true,
            contentText: "Le mot de passe a été mis à jour avec succès!",
            severity: "success",
            handleClose: () => setDisplayNotif(undefined),
          });
        } catch (error: any) {
          console.error(
            "Erreur lors de la mise à jour du mot de passe :",
            error.message
          );
          setDisplayNotif({
            open: true,
            contentText:
              "Aïe, une erreur est survenue lors de la mise à jour du mot de passe.",
            severity: "error",
            handleClose: () => setDisplayNotif(undefined),
          });
        } finally {
          setDisplayAlert(undefined);
        }
      },
      onSubmitAbord: () => setDisplayAlert(undefined),
    });
  };

  return (
    <Box id={style.editProfilePassword} className={style.box}>
      <h2>Modifier mon mot de passe</h2>
      <div className={style.content}>
        <PasswordInput
          label="Ancien mot de passe"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          visible={passwordVisibility.oldPassword}
          onToggleVisibility={() => handleClickShowPassword("oldPassword")}
          onMouseDown={handleMouseDownPassword}
        />
        <PasswordInput
          label="Nouveau mot de passe"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          visible={passwordVisibility.newPassword}
          onToggleVisibility={() => handleClickShowPassword("newPassword")}
          onMouseDown={handleMouseDownPassword}
        />
        <PasswordInput
          label="Confirmer mot de passe"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          visible={passwordVisibility.confirmPassword}
          onToggleVisibility={() => handleClickShowPassword("confirmPassword")}
          onMouseDown={handleMouseDownPassword}
        />
      </div>

      <div className={style.buttonProfile}>
        <Button variant="contained" onClick={handleSave}>
          Enregistrer
        </Button>
      </div>
      {displayAlert?.open === true ? <Alert {...displayAlert} /> : ""}
      {displayNotif?.open === true ? <Notification {...displayNotif} /> : ""}
    </Box>
  );
}

export default ChangePassword;