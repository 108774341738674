import { useAuth } from "../../services/auth/useAuth";
import logo from '../../assets/svg/Logo_JRA_H_NOIR.png';

export default function DisconnectCrossOrigin() {
    const { isConnected, logout } = useAuth();
    logout();
    let url = new URLSearchParams(window.location.search);
    if(atob(url.get('error') || "") !== ""){
        window.location.href = "https://harmonie.jra.tools/login?error="+url.get('error')+"&app="+url.get('app');
    }else{
        if(isConnected !== undefined && !isConnected)
            window.location.href = "https://harmonie.jra.tools/login?auth="+btoa("true")+"&app="+btoa(document.referrer);
    }
    return <>
            {/* add stylesheet and classname break all app */}
            {/* unkonw error */}
            <div style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100vh",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "20px",
                            textAlign: "center",
                            fontStyle: "italic",
                            backgroundColor: "#F7F7F7",
                            zIndex: "9999"
                        }}>
                <img src={logo} style={{maxWidth: "100%"}} alt="logo"/><br/>
                <div>
                    Déconnexion en cours... <br/>
                    Vous serez bientôt redirigé.
                </div>
            </div>
        </>;
}