
import { Button } from '@mui/material';
import style from './openningHours.module.scss';
import styleModal from '../../components/modal/modal.module.scss';
import MenuItem from '@mui/material/MenuItem';

import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useState } from 'react';
import { OpenningHours } from '../../services/object/OpenningHours';
import OpenningHoursItem from './OpenningHoursItem';
import OpenningHoursExeption from './OpenningHoursException';
import { OpenningHoursException } from '../../services/object/OpenningHoursException';
import { useAuth } from '../../services/auth/useAuth';
import { dateJstoSqlDate, jsDateToLocalFr, sqlToJsDate } from '../../services/tools/translateDate';
import { Service_Api } from '../../services/Api';
import uuidv4 from '../../services/tools/uuid';

interface OpenningHoursProps {
    openningHours: OpenningHours[]|undefined;
    openningHoursException:  OpenningHoursException[]|undefined;
    handleFormHoursExceptionChange:  (uid: string, property: keyof OpenningHoursException, value: never) => void;
    handleFormHoursChange:  (uid: string, property: keyof OpenningHours, value: never) => void;
    setOpenningHours: React.Dispatch<React.SetStateAction<OpenningHours[] | undefined>>
    setOpenningHoursException: React.Dispatch<React.SetStateAction<OpenningHoursException[] | undefined>>
  }
function OpenningHoursComp({
    handleFormHoursExceptionChange, 
    handleFormHoursChange, 
    openningHours, 
    openningHoursException,
    setOpenningHours,
    setOpenningHoursException}: OpenningHoursProps) {
    const { user } = useAuth();
    const Api = Service_Api();

    const onClickHandlerAdd = async () => {
        let date = new Date((new Date()).setDate((new Date()).getDate() + 1))
        let newopenningHoursException = {
            uid: uuidv4(),
            date: dateJstoSqlDate(date),
            hourStart:"08:00",
            hourEnd:"18:00",
            userUid: user.uid,
            salepointUid: (openningHours) ? openningHours[0].salepointUid : "",
            isAdditionnal: false,
            isClose: true,
        } as OpenningHoursException
        setOpenningHoursException([
            ...openningHoursException ?? [],
            newopenningHoursException
        ]);
       
    };
    
    const addOpenningHours = async (newopenningHours : OpenningHours) => {
        newopenningHours.uid = uuidv4();
        setOpenningHours([
            ...openningHours ?? [],
            newopenningHours
        ]);
       
    };
    const removeOpenningHoursException = async (newOpenningHoursException : OpenningHoursException) => {
        let dataOpenningHoursException = openningHoursException?.filter((openningHoursException)=>(openningHoursException !== newOpenningHoursException))
        setOpenningHoursException(dataOpenningHoursException);
        if(newOpenningHoursException?.uid && !newOpenningHoursException?.uid.includes("-"))
            Api.del("openningHoursException/", {uid:newOpenningHoursException?.uid});
    };

    
    const addOpenningHoursException = async (newOpenningHoursException : OpenningHoursException) => {
        newOpenningHoursException.uid = uuidv4();
        setOpenningHoursException([
            ...openningHoursException ?? [],
            newOpenningHoursException
        ]);
       
    };
    const removeOpenningHours = async (newopenningHours : OpenningHours) => {
        let dataOpenningHours = openningHours?.filter((openningHours)=>(openningHours !== newopenningHours))
        setOpenningHours(dataOpenningHours);
        if(newopenningHours?.uid && !newopenningHours?.uid.includes("-"))
            Api.del("openningHours/", {uid:newopenningHours?.uid});
    };

    const menuItemList = () => {
        let maxHours = 24;
        let list = [];
        for (let iindex = 0; iindex < maxHours; iindex++) {
            list.push(<MenuItem value={("0"+iindex ).slice(-2)+":00"}>{("0"+iindex ).slice(-2) +":00"}</MenuItem>)
            list.push(<MenuItem value={("0"+iindex ).slice(-2)+":30"}>{("0"+iindex ).slice(-2) +":30"}</MenuItem>)
        }
        return list;
    }

        return (
            <>
                <div className={style.list}>
                    <ul>
                        {
                        (openningHours && openningHours.length > 0) ? 
                            openningHours.map((openningHoursItem:OpenningHours, key:number) => {
                                let firstOfDay = false
                                if(openningHours[key-1] === undefined || openningHours[key-1].day !== openningHoursItem.day)
                                    firstOfDay = true
                                return <OpenningHoursItem key={key} 
                                                          openningHours={openningHoursItem} 
                                                          handleFormHoursChange={handleFormHoursChange} 
                                                          firstOfDay={firstOfDay}
                                                          menuItemList={menuItemList}
                                                          addOpenningHours={addOpenningHours}
                                                          removeOpenningHours={removeOpenningHours}/>
                            })
                        :
                         ""
                        }
                        
                    </ul>
                </div>
                <div className={style.list}>
                    <h2 className={styleModal.subtitle}>Les horaires exceptionnels: </h2>
                    <ul>
                        {
                            (openningHoursException && openningHoursException.length > 0) ? 
                                openningHoursException.map((openningHoursExceptionItem:OpenningHoursException, key:number) => {
                                    let firstOfDay = false
                                    if(openningHoursException[key-1] === undefined || openningHoursException[key-1].date !== openningHoursExceptionItem.date)
                                        firstOfDay = true
                                    return  <OpenningHoursExeption key={key}
                                                                   openningHoursException={openningHoursExceptionItem} 
                                                                   firstOfDay={firstOfDay}
                                                                   menuItemList={menuItemList}
                                                                   handleFormHoursExceptionChange={handleFormHoursExceptionChange}
                                                                   addOpenningHoursException={addOpenningHoursException}
                                                                   removeOpenningHoursException={removeOpenningHoursException}/>
                                })
                            :
                                <p>Aucun horaire exceptionnel de prévu</p>
                        }
                    </ul>
                    <Button onClick={onClickHandlerAdd}>
                        <SpeedDialIcon/> Ajouter une exception
                    </Button>
                </div>
            </>
      );
}


export default OpenningHoursComp;