import style from './login.module.scss';
import logo from '../../assets/svg/Logo_JRA_H_NOIR.png';
import GoogleButton from '../../components/googleAuth/GoogleButton';
import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {Service_Api} from '../../services/Api';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography  } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { useAuth } from '../../services/auth/useAuth';


const Login = ({setToken}:any) => {
    const navigate = useNavigate();
    const [email, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    const Api = Service_Api();
    let url = new URLSearchParams(window.location.search);
    
    const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>|FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      let body = {
        email: email,
        password: password
      }
      let data = (await (Api.post('user/connexion', body, {})))?.data
      if(data){
        setError(false);
        let user = null;
        if(data.user)
          user = data.user

        let app = null;
        if (data?.app) {
                app = data.app
        }
        let law = null;
        if (data?.law) {
            law = data.law
        }
        let job = null;
        if (data?.job) {
            job = data.job
        }
        navigate("/connexion-en-cours",
          {
            state: {
              userPost : user,
              lawPost : law,
              appPost : app,
              jobPost : job
            }
          }
        );
      }else{
          setError(true)
          console.log(data)
      }
    };
    
  return (
    <div className={style.content}>
        <div className={style.brand}>
            <div className={style.logo_container}>
                <img src={logo} alt="logo"/>
            </div>
        </div>
        <div className={style.login}>
            <div className={style.container_form}>
                <div className={style.heading}>
                    <h1>CONNEXION</h1>
                    <h2>Bienvenue ! Connectez-vous pour accéder au dashboard :</h2>
                </div>
                <form  autoComplete="off" action="" method="POST" onSubmit={(e)=>{handleLogin(e)}}>
                    <TextField  id="email" 
                                label="Nom d'utilisateur"
                                name="email"
                                autoComplete="email"
                                required
                                onChange={e => setUserName(e.target.value)}
                                error={error}
                                sx={{ m: 1, width: '100%' }}
                                />
                    <FormControl sx={{ m: 1, width: '100%' }} required>
                        <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end" >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                            }
                            name="password"
                            autoComplete="password"
                            onChange={e => setPassword(e.target.value)}
                            label="Mot de passe"
                            required
                            error={error}
                        />
                    </FormControl>
                    
                    {
                    (atob(url.get('error')|| "") !== "") ? 
                        <Typography color="error">
                            { atob(url.get('error')|| "") } Si c'est une erreur, envoyer un mail à dev@autobonplan.com 
                            Application concernée : { atob(url.get('app')|| "") }
                        </Typography>
                        :
                        <Typography className={((error) ? "" : style.hidden)} 
                                        color="error">
                                        Le nom d'utilisateur ou le mot de passe renseigné est incorrect.
                        </Typography>
                    }
                    <div className="button-container">
                        <Button type="submit" 
                                variant="contained" 
                                onClick={(e)=>{handleLogin(e)}}>
                            Connexion
                        </Button>
                    </div>
                    <div>
                        <Link to={'/mot-de-passe'} title="Revenir à l'accueil">
                            <span>Mot de passe oublié</span>
                        </Link>
                    </div>
                    <div className="google_connect_btn">
                        <GoogleButton setToken={setToken}/>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Login;