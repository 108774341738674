

export async function fetchFavicon(url: string): Promise<string> {
    try {
        if (!url) {
            return '';
        }
        const response = await fetch(url);
        const html = await response.text();
        const shortcutIconMatch = html.match(/<link\s+rel="shortcut icon"\s+href="([^"]+)"/i);
        if (shortcutIconMatch && shortcutIconMatch[1]) {
            return new URL(shortcutIconMatch[1], url).href;
        }
        const iconMatch = html.match(/<link\s+rel="icon"\s+href="([^"]+)"/i);
        if (iconMatch && iconMatch[1]) {
            return new URL(iconMatch[1], url).href;
        }
        return '';
    } catch (error) {
        return '';
    }
}