import clsx from "clsx";
import { forwardRef, useEffect, useState } from "react";
import { Service_Api } from "../../services/Api";
import style from "./assets/scss/accueil.module.scss";

import { Post } from "../../services/object/Post";
import { 
  Univers, 
  UniversLogos 
} from "../../services/object/Univers";

import modalStyle from "../../components/modal/modal.module.scss";

import noPhotoNewEmployee from "./assets/images/noPhotoNewEmployee.jpg";

import { Modal } from "@mui/base/Modal";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

interface NewEmployeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  newEmployeeData: Post;
}

export default function NewEmployeeModal({
  isOpen,
  onClose,
  newEmployeeData,
}: NewEmployeeModalProps) {
  const [universImage, setUniversImage] = useState<string>("");
  const [salepointName, setSalepointName] = useState<string>("");

  if (!isOpen) return null;

  return (
    <div>
      <Modal
        id={style.newEmployeeModal}
        className={modalStyle.StyledModal}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={isOpen}
        onClose={onClose}
        slots={{ backdrop: Backdrop }}
      >
        <div className={modalStyle.box}>
          <div className={style.modalHeader}>
            <Tooltip title="Fermer">
              <button className={modalStyle.close} onClick={onClose}>
                <CloseIcon />
              </button>
            </Tooltip>
            <span className={style.postCategory}>collaborateur</span>
          </div>
          <div className={style.modalContent}>
            <div className={style.imageBox}>
              <img
                src={
                  newEmployeeData.photo
                    ? newEmployeeData.photo
                    : noPhotoNewEmployee
                }
                alt="nouveau collaborateur"
              />
              {newEmployeeData.univers ? (
                <img src={UniversLogos[newEmployeeData.univers.uid]} className={style.universLogo} alt="Univers" />
              ) : null }
              <span>
                {newEmployeeData.title} {newEmployeeData.subtitle}
              </span>
            </div>
            <div className={style.highlighted}>
              <h2>Bienvenue {newEmployeeData.title}</h2>
              {newEmployeeData.newEmployeeJob ? (
                <h3>{newEmployeeData.newEmployeeJob}</h3>
              ) : null }
              {newEmployeeData.salepoint ? (
                <h3>{newEmployeeData.salepoint.name}</h3>
              ) : null }
            </div>
            <p>{newEmployeeData.description}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const Backdrop = forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, modalStyle.Backdrop)}
      ref={ref}
      {...other}
    />
  );
});
