
import * as React from "react";
import FormPost from "../config/AccueilConfig/modals/FormPost";
import { Post } from "../../services/object/Post";
import { SalepointData } from "../../services/object/Salepoint";
import style from "../config/AccueilConfig/styles/accueilConfig.module.scss";

interface ModalSuggestedPostProps {
  errorMessages: Record<string, string>;
  handlePostChange: (property: string, value: string | number | SalepointData | null) => void;
  formPost: Post | undefined;
  formRefPost: React.RefObject<HTMLFormElement>;
}

export default function ModalSuggestedPost({
  errorMessages,
  handlePostChange,
  formPost,
  formRefPost,
}: ModalSuggestedPostProps) {
  return [
    <form className={`${style.uiform} ${style.postForm}`} ref={formRefPost}>
      <FormPost
        onChange={handlePostChange}
        formPost={formPost}
        errorMessages={errorMessages}
        isSuggestionForm={true}
      />
    </form>
  ];
}
