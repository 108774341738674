import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { NavLink } from 'react-router-dom';
import style from './assets/scss/lienUtile.module.scss';
import ExtensionIcon from '@mui/icons-material/Extension';
import { App } from '../../services/object/App';
import { fetchFavicon } from '../../services/tools/fetchFavicon';

import { Service_Api } from "../../services/Api";
import { useAuth } from "../../services/auth/useAuth";


const LienUtile = () => {
  const Api = Service_Api();
  const { user } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [TOUserApp, setTOUserApp] = useState<App[]>([]);
  const [TOUrlApp, setTOUrlApp] = useState<string[]>([""]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [anchorEl]);
  useEffect(() => {
    fetchAppsByUserUid();
  }, []);


  const fetchAppsByUserUid = async () => {
    try {
      const response = await Api.get(
        "app/getAppsOrderByUserUid",
        "userUid=" + user.uid
      );
      const T_userApps = response?.data;
      const filteredApps = T_userApps?.filter((app: App) => app.isJraApp === false);
  
      setTOUserApp(filteredApps);
      console.log("Données récupérées avec succès", filteredApps);

      fetchFavicon(filteredApps);
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  };
  
  const fetchFavicon = async (TOUrl: [App]) => {
    let TJSXApp = [];
    if (TOUrl && TOUrl.length > 0) {
      for (const OUrl of TOUrl) {
        let url = OUrl.url;
        try {
          const response = await fetch(url);
          const html = await response.text();
          const iconMatch = html.match(
            /<link\s+[^>]*rel=["'](?:shortcut icon|icon)["'][^>]*href=["']([^"']+)["']/i
          );

          if (iconMatch && iconMatch[1]) {
            const iconUrl = new URL(iconMatch[1], url).href;

            try {
              const iconResponse = await fetch(iconUrl, { method: "HEAD" });

              if (iconUrl.includes("callcenter"))
                console.log('iconResponse:', iconResponse)

              if (iconResponse.ok) {
                TJSXApp.push(iconUrl);
              } else {
                TJSXApp.push("");  // URL inaccessible
              }
            } catch (iconError) {
              console.error("Error fetching icon:", iconError);
              TJSXApp.push("");  // Erreur d'accès à l'icône
            }
          } else {
            TJSXApp.push("");  // Pas d'icône trouvée
          }

        } catch (error) {
          console.error("Error fetching favicon:", error);
          TJSXApp.push("");
        }
      }
    }
    setTOUrlApp(TJSXApp);
  };


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        className={style.popperRoot}
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} timeout={350}>
            <Paper className={`${style.popperContent} hoverShadow`}>
              <div className={style.iconUtile}>
                <ul>
                  {TOUserApp.map((app, index) => (
                    <li key={index}>
                      <NavLink target="_blank" to={app.url}>
                        <Button color='info' variant="text" className={style.button}>
                          {TOUrlApp[index] ? (
                            <img src={TOUrlApp[index]} alt={app.name} />
                          ) : (
                            <ExtensionIcon />
                          )}
                          <p>{app.name}</p>
                        </Button>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </Paper>
          </Slide>
        )}
      </Popper>
      <IconButton size="small" sx={{ marginBlock: '0.3rem', marginInline: '0.5rem' }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
}

export default LienUtile;
