import React, { useState, useEffect, ChangeEvent } from 'react';
import { ReactComponent as Pen} from '../assets/editPhoto.svg';
import TextField from '@mui/material/TextField';
import {User} from '../../services/object/User';
import style from './Utilisateur.module.scss';
import { getBase64 } from '../../services/tools/imgToBase64';

interface FormProps {
  onChange: (property: string, value: string) => void;
  formUsers: User | undefined;
  errorMessages: Record<string, string>;
}

const Form = ({ onChange, formUsers, errorMessages }: FormProps) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>();
  const [localFormValues, setLocalFormValues] = useState({
    lastname: formUsers?.lastname ?? '',
    firstname: formUsers?.firstname ?? '',
    email: formUsers?.email ?? '',
  })
  
  useEffect(() => {
    if (formUsers) {
      setLocalFormValues({
        lastname: formUsers.lastname ?? '',
        firstname: formUsers.firstname ?? '',
        email: formUsers.email ?? '',
      });
    }
  }, [formUsers]);

  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    handleChange(name, value);
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      getBase64(file,(result:string) => {
          onChange("photo", result)
      })
    } else {
      setSelectedImage(undefined);
    }
  };

  const handleCircleClick = () => {
  const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const capitalizeFirstLetter = (value: string) => {
    return value.replace(/(^\w|\.\s*\w)/g, (match) => match.toUpperCase());
  };

  const handleChange = (field: string, value: string) => {
    const capitalizedValue = (field === "email") ? value : capitalizeFirstLetter(value);
    onChange(field, capitalizedValue);
  };


  return (
        <div className={style.content}>
            <div className={style.name}>
              <TextField 
                  fullWidth
                  label= 'Prénom'   
                  size="small"
                  sx={{marginBlock: '0.5rem'}}
                  name="firstname"
                  value={localFormValues.firstname}
                  onChange={handleLocalChange}
                  onBlur={handleBlur}
                  required
                  error={!!errorMessages.firstname}
                  helperText={errorMessages.firstname}
                  InputLabelProps={{
                    shrink: !!formUsers?.firstname,
                  }}
              />
              <TextField 
                  fullWidth 
                  label= 'Nom'
                  size="small"
                  sx={{marginBlock: '0.5rem'}}
                  name="lastname"
                  value={localFormValues.lastname}
                  onChange={handleLocalChange}
                  onBlur={handleBlur}
                  required
                  error={!!errorMessages.lastname}
                  helperText={errorMessages.lastname}
                  InputLabelProps={{
                    shrink: !!formUsers?.lastname,
                  }}
              />
              <TextField 
                  fullWidth 
                  label= 'Email'
                  size="small"
                  type={"email"}
                  name="email"
                  sx={{marginTop: '0.5rem'}}
                  value={localFormValues.email}
                  onChange={handleLocalChange}
                  onBlur={handleBlur}
                  required
                  error={!!errorMessages.email}
                  helperText={errorMessages.email}
                  InputLabelProps={{
                    shrink: !!formUsers?.email,
                  }}
              />
            </div>
            <div
                className={style.circleContainer + ' ' + ((selectedImage) ?  style.active : "")}
                onClick={handleCircleClick}
                style={{
                    backgroundImage: selectedImage ? `url(${selectedImage})` : formUsers?.photo ? `url(${formUsers?.photo})` : 'none',
                }}
            >
                <Pen/>
            </div>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                id="fileInput"
                name="photo"
                style={{
                    display: 'none',
                }}
            />
        </div>
  );
};

export default Form;
