import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormLabel from '@mui/material/FormLabel';
import style from './input.module.scss';

interface CheckboxGroupProps{
  legend: string;
  objetParent: any;
  objetChild: any;
  childChecked: ChildProps[];
  setChildChecked: React.Dispatch<React.SetStateAction<ChildProps[]>>;
  checkedOfChild: string[];
  checkedOfParent: string[];
  errorMessages: Record<string, string>;
  required: boolean;
  objectParentName: string;
  isSoloCheckbox?: boolean;
  initialVisibility?: boolean;
}

interface ChildProps {
  uid: string;
  parentUid: string;
  value: boolean;
}
interface ParentProps {
  uid: string;
  value: boolean;
}

export default function CheckboxGroup ({
                                        initialVisibility, 
                                        objetParent, 
                                        objetChild, 
                                        legend, 
                                        childChecked, 
                                        setChildChecked, 
                                        checkedOfChild, 
                                        checkedOfParent, 
                                        errorMessages, 
                                        required, 
                                        objectParentName, 
                                        isSoloCheckbox
                                      }: CheckboxGroupProps) {
  const [parentVisibility, setParentVisibility] = React.useState<{[key: string]: boolean }>({});
  const [parentChecked, setParentChecked] = React.useState<ParentProps[]>([{  uid: "", value: false}]);
  const [allChecked, setAllChecked] = React.useState(false);
  const [someChecked, setSomeChecked] = React.useState(false);

  React.useEffect(() => {
    let parentList: ParentProps[] = [];
    let childList: ChildProps[] = []; 

    if (objetParent.length !== 0) {
      for (const parent of objetParent) {
        parentList = [...parentList, { uid: parent.uid, value: checkedOfParent.includes(parent.uid) }];
        if (objetChild.length !== 0) {
          for (const child of objetChild) {
            if(child[objectParentName +"Uid"] === parent.uid)
              childList = [...childList,{ uid: child.uid, parentUid: child[objectParentName +"Uid"], value: checkedOfChild.includes(child.uid) },];
          }
        }
      }
      setChildChecked(childList);
      setParentChecked(parentList);
      setSomeChecked(childChecked.length > 0 && childChecked.some(child => child.value) && !allChecked);
      setAllChecked(parentList.every(parent => parent.value));
    }

  }, [objetParent, objetChild, checkedOfChild, checkedOfParent]); 

  React.useEffect(() => {
    const initialParentVisibility: { [key: string]: boolean } = {};
    objetParent.forEach((parent: any) => {
      initialParentVisibility[parent.uid] = initialVisibility || false;
    });
    setParentVisibility(initialParentVisibility);
  }, [initialVisibility, objetParent]);
  
  const handleParentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    
    //if child in other parent
    let childUidList:string[] = [];
    for ( const child of childChecked) {
      if(target.value === child.parentUid){
        childUidList.push(child.uid)
      }
    }

    let childList:ChildProps[] = [];
    let parentUidList:string[] = [];
    for ( const child of childChecked) {
      let value;
      if(target.value === child.parentUid || childUidList.includes(child.uid)){
        value = target.checked;
        parentUidList.push(child.parentUid)
      }else{
        value = child.value;
      }
      childList = [...childList, { uid: child.uid, parentUid: child.parentUid, value: value}];
    }
    
    let parentList:ParentProps[] = [];
    for ( const parent of parentChecked) {
      let value;
      if(target.value === parent.uid || parentUidList.includes(parent.uid)){
        value = target.checked;
      }else{
        value = parent.value;
      }
      parentList = [...parentList, { uid: parent.uid, value: value}];
    }
    setParentChecked(parentList);
    setChildChecked(childList);
    setSomeChecked(childList.some(child => child.value));
    setAllChecked(parentList.every(parent => parent.value));
  };
  
  const handleChildChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    let childList:ChildProps[] = [];
    let TcurrentParentUid:string[] = [""];
    for ( const child of childChecked) {
      childList = [...childList, { uid: child.uid,  parentUid: child.parentUid,value: (target.value === child.uid) ? target.checked : child.value}];
      if((target.value === child.uid)){
        TcurrentParentUid.push(child.parentUid);
      }
    }
      
    for ( const currentParentUid of TcurrentParentUid) {
      if(!isIndeterminate(currentParentUid, childList)){
        let parentList:ParentProps[] = [];
        for ( const parent of parentChecked) {
          parentList = [...parentList, { uid: parent.uid, value: (currentParentUid === parent.uid) ? target.checked : parent.value}];
        }
        setParentChecked(parentList);
      }
    }
    let updatedParentList = parentChecked.map(parent => {
      const childrenOfParent = childList.filter(child => child.parentUid === parent.uid);
      const parentCheckedStatus = childrenOfParent.every(child => child.value);
      return { ...parent, value: parentCheckedStatus };
    });
    setChildChecked(childList);
    setSomeChecked(childList.some(child => child.value));
    setAllChecked(updatedParentList.every(parent => parent.value));
  };
   
  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    const updatedChildList = childChecked.map(child => ({ ...child, value: isChecked }));
    const updatedParentList = parentChecked.map(parent => ({ ...parent, value: isChecked }));

    setChildChecked(updatedChildList);
    setParentChecked(updatedParentList);
    setAllChecked(isChecked);
    setSomeChecked(isChecked);
  };

  const isIndeterminate = (uid :string, TUid:ChildProps[])=>{
    let someChecked:boolean =false;
    let someNotChecked:boolean =false;
    TUid.forEach((element:any) => {
      if(element.parentUid === uid && !element.value ){
        someNotChecked = true;
      }
      if(element.parentUid === uid && element.value){
        someChecked = true;
      }
    });
    return someChecked && someNotChecked;
  }
   
  const getValueOfCheckbox = (uid :string, checkedProps: ChildProps[]|ParentProps[])=>{
    return checkedProps.some((element:ChildProps|ParentProps, index : number) => {
      if(element.uid === uid){
        return element.value;
      }
    });
  }


  const toggleChildVisibility = (parentUid: string) => {
    setParentVisibility((prevParentVisibility) => ({
      ...prevParentVisibility,
      [parentUid]: !prevParentVisibility[parentUid],
    }));
  };

  const toggleChildVisibilityForParent = (parentUid: string) => {
    const isParentChecked = getValueOfCheckbox(parentUid, parentChecked);
      setParentVisibility((prevParentVisibility) => ({
        ...prevParentVisibility,
        [parentUid]: !isParentChecked, 
      }));
  };

  function noParentCheckedOrIndeterminate() {
    for (const parent of parentChecked) {
      if (parent.value || isIndeterminate(parent.uid, childChecked)) {
        return false;
      }
    }
    return true && required;
  }


  let errorName = "";

  let isError = parentChecked.every(parent => !parent.value && !isIndeterminate(parent.uid, childChecked)) && Object.keys(errorMessages).length !== 0;
  return (
    <div  className={style.containercheck}>
     <div className={`${style.uicheckbox} ${isSoloCheckbox ? style.soloCheckbox : ''}`}>
        <FormLabel required={noParentCheckedOrIndeterminate() ?? required} component="legend" error={isError}>{legend}</FormLabel>
        <FormControlLabel
          label="Tout sélectionner"
          control={
            <Checkbox
              checked={allChecked}
              indeterminate={someChecked && !allChecked}
              onChange={handleSelectAllChange}
            />
          }
        />
        {objetParent.map((parent: any, key: number) => {
          errorName = ((parentChecked.length > 0) ? "" : parent.name)
          return (<FormGroup className={style.checkGrid} key={key}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <FormControlLabel
                control={<Checkbox 
                  value={parent.uid} 
                  checked={getValueOfCheckbox(parent.uid, parentChecked)}
                  indeterminate={isIndeterminate(parent.uid, childChecked)}
                  onChange={handleParentChange}
                  required={noParentCheckedOrIndeterminate() ?? required} />}
                value={parent.uid}
                label={parent.name}
                onClick={() => toggleChildVisibilityForParent(parent.uid)}
              />
              <IconButton size="small" onClick={() => toggleChildVisibility(parent.uid)}>
                <ExpandMoreIcon
                  style={{
                    transform: parentVisibility[parent.uid] ? 'rotate(360deg)' : 'rotate(270deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </IconButton>          
            </div>
            {parentVisibility[parent.uid] && (
              objetChild.map((child: any, key: number) => (
                (child[objectParentName +"Uid"] === parent.uid ) ?
                <Box key={key} sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <FormControlLabel
                    value={child.uid}
                    label={child.name}
                    control={<Checkbox 
                      value={child.uid}  
                      checked={getValueOfCheckbox(child.uid, childChecked)}
                      onChange={handleChildChange}/>} 
                  />
                </Box>
              : "" ))
            )}
          </FormGroup>)
        })}   
      </div>
      {(noParentCheckedOrIndeterminate() ?? required)&& <div className={style.errorMessage}> {errorMessages[errorName]}</div>}
    </div>
  );
}
