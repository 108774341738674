import styleModal from '../../components/tinyModal/tinyModal.module.scss';
import { Button } from '@mui/material';
import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { style } from '@mui/system';
interface SalepointTinyPopupChildProps {
    handleButtonGoogleSubmit: () => void
    handleClickButtonTinyModalHours: () => void
}
const SalepointTinyChildPopup = ({handleButtonGoogleSubmit,  handleClickButtonTinyModalHours} :SalepointTinyPopupChildProps) => {
    return (
        <>
            <p className={styleModal.items_large}>Ces informations seront diffusées sur les fiches GoogleMyBusiness</p>
            <div className={styleModal.items}>
                
                <Button variant="outlined"
                        onClick={() => handleClickButtonTinyModalHours()} 
                        endIcon={<CancelScheduleSendIcon/>}>
                    Non
                </Button>
                
            </div>
            <div className={styleModal.items}>
                <Button variant="contained" onClick={() => (handleButtonGoogleSubmit())}
                         endIcon={<SendIcon/>}> 
                    Oui
                </Button>
            </div>
        </>
    )
}
export default SalepointTinyChildPopup;