import { ReactComponent as GoogleIcon } from './googleIcon.svg';
import Button from '@mui/material/Button';
import {Service_Api} from '../../services/Api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/auth/useAuth';
import Cookies from 'universal-cookie';
import { App } from '../../services/object/App';
import { Law } from '../../services/object/Law';

function GoogleButton({setToken}:any) {
  const navigate = useNavigate();
  const href =
  'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=825924404102-495ec7c645ktadnk73l8t7a95plp7dm7.apps.googleusercontent.com' +
  '&redirect_uri=https://harmonie.jra.tools/login'+
  '&scope=email%20profile&approval_prompt=auto';
  // gère la connexion à google et la redirection
  const handleGoogleConnect = async () => {
    // récupère le code google avec des paramétrage de l'url
    const url = new URLSearchParams(window.location.search);
    const code = url.get('code');
    if (code) {
      //crée un objet de données avec le code pour l'envoyer au serveur 
      const Api = Service_Api();
      //effectue une requête POST au serveur pour gérer l'authentification avec google
      const response = await (Api.post('user/connexionGoogle', { code: code }))
      let data = response?.data
      if (response?.success){
        let user = null;
        if(data.user)
          user = data.user

        let app = null;
        if (data?.app) {
                app = data.app
        }
        let law = null;
        if (data?.law) {
            law = data.law
        }
        let job = null;
        if (data?.job) {
            job = data.job
        }
        navigate("/connexion-en-cours",
          {
            state: {
              userPost : user,
              lawPost : law,
              appPost : app,
              jobPost : job
            }
          }
        );
      }else {
        console.log(response)
      };
    }
  };
  
  let url = new URLSearchParams(window.location.search);
  if(atob(url.get('auth')|| "") === "true"){
    (new Cookies()).set('crossConnexion', {app : url.get('app'), expires : new Date((new Date()).getTime() + 5 * 60000)})
  }
    

handleGoogleConnect();

  return (
    <a id="google_connect_btn" href={href}>
      <Button
        sx={{ width: '16rem' , boxShadow: '0px 0px 8px #0000003d', borderRadius: '20px', marginTop: '5rem' }}
        size="medium"
        variant="text"
        component="label"
        startIcon={<GoogleIcon />}
      >
        Connexion avec Google
      </Button>
    </a>
  );
}

export default GoogleButton;

