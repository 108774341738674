import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertColor } from '@mui/material';

export const renderNotif = (isSuccess: boolean, purpose: string, handleClose: () => void) => {
    let contentTextString = ""
    let severity: AlertColor = "info"

    if(isSuccess) {
      severity = "success"
      switch (purpose) {
        case "post":
          contentTextString = "L'actualité a été enregistrée avec succès."
          break;
        case "put":
          contentTextString = "L'actualité a été mise à jour avec succès."
          break;
        case "copy":
          contentTextString = "L'actualité a été dupliquée avec succès."
          break;
        case "suggest":
          contentTextString = "L'actualité a été envoyée avec succès."
          break;
        case "del":
          contentTextString = "L'actualité a été supprimée avec succès."
          break;
      }
    } else {
      severity = "error"
      switch (purpose) {
        case "post":
          contentTextString = "Une erreur est survenue lors de l'enregistrement de l'actualité."
          break;
        case "put":
          contentTextString = "Une erreur est survenue lors de la mise à jour de l'actualité."
          break;
        case "copy":
          contentTextString = "Une erreur est survenue lors de la duplication de l'actualité."
          break;
        case "suggest":
          contentTextString = "Une erreur est survenue lors de l'envoi de l'actualité."
          break;
        case "del":
          contentTextString = "Une erreur est survenue lors de la suppression de l'actualité."
          break;

      }
    }
    return {
      open: true,
      contentText: contentTextString,
      severity: severity,
      handleClose: handleClose
    }
}
export interface NotificationProps {
  open : boolean,
  contentText : string,
  severity : AlertColor,
  handleClose:  () => void
}

export default function Notification({open,contentText,severity,handleClose}:NotificationProps) {
  return (
      <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'  }} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {contentText}
          </Alert>
      </Snackbar>
  );
}
