import { Univers } from "./Univers";
import { SalepointData } from "./Salepoint";
import { User } from "./User";

export interface Post {
  uid: string;
  title: string;
  subtitle: string | null;
  description: string | null;
  url: string | null;
  urlYoutube: string | null;
  photo: string | null;
  newEmployeeJob: string | null;
  univers: Univers | null;
  salepoint: SalepointData | null;
  postCategoryId: number | string | null;
  isImportantPost: boolean | string;
  likeNumber: number;
  isDraft: boolean;
  isSuggestedPost: boolean;
  createdBy: User | null;
  date: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | string;
}

export const postCategoriesNews = 1;
export const postCategoriesNewEmployee = 2;
