import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import style from './input.module.scss';
import style_salepoint from '../../pages/Salepoint/salepoint.module.scss';
import { RadioGroup } from '@mui/material';

interface RadioProps {
  legend: string;
  onChange: (object: string) => void;
  Objet: any;
  checkedUid: string|undefined;
  errorMessages: Record<string, string>;
  required: boolean
}

export default function RadioOnly({ Objet, legend, onChange, checkedUid, errorMessages, required}: RadioProps) {

  // inutile ?
  // const [selectedOption, setSelectedOption] = useState<string | undefined>(checkedUid);

  const handleRadioChange = (uid: string) => {
    // inutile ?
    // setSelectedOption(uid);
    onChange(uid);
  };
  return (
    <div className={style.containercheck}>
      <div className={style.uicheckbox +" "+ style_salepoint.uicheckbox}>
        <FormLabel required={required} component="legend" error={!!errorMessages[legend]}>{legend}</FormLabel>
        <RadioGroup sx={{flexDirection: 'column'}} name={legend}>
          {Objet.map((object: any) => {
              return <FormControlLabel
                  control={<Radio value={object.uid} />}
                  key={object.uid}
                  label={object.name} 
                  checked={checkedUid === object.uid}
                  onChange={() => handleRadioChange(object.uid)}
                  required={required}
                  />
            })}
        </RadioGroup>
        </div>
      <div className={style.errorMessage}>{errorMessages[legend]}</div>
    </div>
  );
};
