import style from './law.module.scss';
import styleModal from '../../components/modal/modal.module.scss';
import { TextField } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import React from 'react';
import { App } from '../../services/object/App';
import RadioOnly from '../../components/input/RadioOnly';

interface AppPopupChildrensProps {
    errorMessages: Record<string, string>, 
    formRefApp: React.RefObject<HTMLFormElement>, 
    app: App| undefined, 
    handleFormAppChange: (property: string, value: string) => void
  }
    const appPopupChildrens = ({  errorMessages, 
                                  formRefApp, 
                                  app, 
                                  handleFormAppChange,
                                } :AppPopupChildrensProps) => {

    const onChangeJraApp = (value: string)=>{
      handleFormAppChange('isJraApp', value)
    }                           
    return ([
            <form className={style.uiform} ref={formRefApp}>
                <div className={style.contentText}>
                  <TextField fullWidth label="Nom"  
                      size="small"
                      value={app?.name}
                      name="name"
                      onChange={(e) => handleFormAppChange('name', e.target.value)} required
                      error={!!errorMessages.name}
                      helperText={errorMessages.name}/>
                  <TextField fullWidth label="Url"  
                      size="small"
                      value={app?.url}
                      name="url"
                      onChange={(e) => handleFormAppChange('url', e.target.value)} required
                      error={!!errorMessages.url}
                      helperText={errorMessages.url}/>
                  <RadioOnly
                      Objet={[{"uid": "0", "name": "Non"},{"uid": "1", "name": "Oui"}]} 
                      legend="Est une application interne (dev maison)"
                      onChange={onChangeJraApp}
                      checkedUid={(app?.isJraApp?.toString()) === "true" ? "1" : "0"}
                      errorMessages={errorMessages}
                      required={true}
                    />
                </div>
            </form>
    ])
}
export default appPopupChildrens;