import Cookies from 'universal-cookie';

export const usePopup = () => {
    const getPopupStatus = () => {
        const popupString = (new Cookies()).get('JRA_popup') ?? "";
        let popups = [];
        if(popupString !== "" && typeof popupString === "string")
            popups = JSON.parse(popupString)
          else if (popupString !== "")
            popups = popupString
        return popups;
    };

    const savePopup = (popups: string[]) => {
        (new Cookies()).set('JRA_popup', JSON.stringify(popups));
    };
    const popupStatus = getPopupStatus();

    const markPopupAsDisplayed = (popupUid: string) => {
        const updatedPopups = [...popupStatus, popupUid];
        savePopup(updatedPopups);
    };
    const isPopupDisplayed = (popupUid: string) => {
        return popupStatus.includes(popupUid);
    };
    return { isPopupDisplayed, markPopupAsDisplayed };
}