import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './assets/scss/navbar.module.scss';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Person as PersonIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Wysiwyg as WysiwygIcon,
  Dashboard
} from '@mui/icons-material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import base_doc from '../../assets/iconsNavOut/base_doc-icon.png';
import offres_emploi from '../../assets/iconsNavOut/offres_emploi-icon.png';
import handicap from '../../assets/iconsNavOut/handicap-icon.png';
import e_learning from '../../assets/iconsNavOut/e_learning-icon.png';
import { useApp } from '../../services/auth/useApp';
import ExtensionIcon from '@mui/icons-material/Extension';
import ReactDOM from 'react-dom';
import { StepIconProps } from '@mui/material/StepIcon';
import { fetchFavicon } from '../../services/tools/fetchFavicon';
import { App } from '../../services/object/App';
import { Law, newsConfig, popinConfig, salepointConfig, userConfig } from '../../services/object/Law';
import { useLaw } from '../../services/auth/useLaw';


const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gestionMenuOpen, setGestionMenuOpen] = useState(false);
  const location = useLocation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLInputElement>(null);
  const gestionPages = ['/utilisateurs', '/concession', '/popins', '/accueilConfig'];
  const isOpen = gestionPages.includes(location.pathname);
  const { law } = useLaw();
  const { TOApp } = useApp();

  const [appNav, setAppNav] = useState<App[]>([]);

  useEffect(() =>{
    if(TOApp){
      let jraApps: App[] = [];
      TOApp.map((a: App) => {
        fetchFavicon(a.url).then((url :string ) => {
          a.url = url;
          jraApps.push(a)
        });
      })
    setAppNav(jraApps)
  }
  },[TOApp])


  function fetchIcon(faviconUrl: string): React.ReactNode {
    try {
      if (faviconUrl) {
        return <img src={faviconUrl} alt=''  
         onError={(e) => {
          e.currentTarget.style.display = 'none';
          console.error('Error loading favicon:', faviconUrl);
          const parent = e.currentTarget.parentNode as HTMLElement | null;
          if (parent) {
            const noIconElement = document.createElement('div');
            ReactDOM.render(<ExtensionIcon htmlColor="#008EC8"/>, noIconElement);
            parent.appendChild(noIconElement);
          }
        }}
        />;
      } else {
        return <div><ExtensionIcon htmlColor="#008EC8"/></div>;
      }
    } catch (error) {
      console.error('Error fetching favicon:', error);
      return <div>error</div>;
    }
  }

  function CustomStepIcon(props: StepIconProps & { url: string, icon: React.ReactNode }) {
    const {icon} = props;
    
    return (
      <div className={style.iconUrl}>
        {icon}
      </div>
    );
  }

  const toggleDrawer = () => {
    if (!drawerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    setDrawerOpen(!drawerOpen);
  }

  const handleClickGestionMenu = () => {
    setGestionMenuOpen(!gestionMenuOpen);
  }

  useEffect(() => {
    setGestionMenuOpen(isOpen);
  }, [location.pathname]);

  const closeDrawer = () => {
    setDrawerOpen(false);
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setDrawerOpen(false);
    }
  };

  return (
    <div>
      <React.Fragment key={'left'}>
        <div className={style.offcanvasMenu}>
          <input
            ref={buttonRef}
            onClick={toggleDrawer}
            type='checkbox'
            role='button'
            aria-label='Display the menu'
            className={style.menuButton}
            checked={drawerOpen}
          />
        </div>
        <Drawer
          ref={drawerRef}
          anchor={'left'}
          open={drawerOpen}
          className={`nav ${style.nav}`}
          onClose={toggleDrawer}
        >
          <Box className={style.box}>
            <List className={style.list}>

              <ListItem
                disablePadding
                className={style.listitem + ' ' + (location.pathname === '/accueil' ? style.active : '')}
              >
                <Link onClick={closeDrawer} to='/accueil'>
                  <ListItemButton>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    Accueil
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                disablePadding
                className={style.listitem + ' ' + (location.pathname === '/toutes-actus' ? style.active : '')}
              >
                <Link onClick={closeDrawer} to='/toutes-actus'>
                  <ListItemButton>
                    <ListItemIcon><NewspaperIcon /></ListItemIcon>
                    Actualités
                  </ListItemButton>
                </Link>
              </ListItem>

              {appNav.filter((a: App) => a.isJraApp === true && !(a.uid === '655cb071f404d0.41655118') ).map((a, index) => {
                return (
                <ListItem
                  key={index}
                  disablePadding
                  className={style.listitem + ' ' + (location.pathname === a.url ? style.active : '')}
                >
                  <Link onClick={closeDrawer} target="_blank" to={a.url}>
                    <ListItemButton>
                    <ListItemIcon><CustomStepIcon url={a.url} icon={fetchIcon(a.url ?? "")}/></ListItemIcon>
                      {a.name}
                    </ListItemButton>
                  </Link>
                </ListItem>
                )
              })}
              
              {/* menu Gestion */}
              { (law.filter((law : Law)=>(law.uid === userConfig ||
                                          law.uid === newsConfig ||
                                          law.uid === salepointConfig ||
                                          law.uid === popinConfig
                )).length >= 1) ? 
                <>
                  <ListItemButton onClick={handleClickGestionMenu} className={`${style.submenuButton} ${gestionPages.includes(location.pathname) ? style.active : ''}`}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <div className={style.textIcon}>
                      <p>Gestion</p>
                      {gestionMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  </ListItemButton>
                  <Collapse in={gestionMenuOpen} timeout="auto" unmountOnExit className={style.submenu}>
                    <List component="div" disablePadding>
                      { (law.filter((law : Law)=>(law.uid === userConfig)).length === 1) ? 
                          <ListItem
                              disablePadding
                              className={style.listitem + ' ' + (location.pathname === '/utilisateurs' ? style.active : '')}
                            >
                              <Link onClick={closeDrawer} to="/utilisateurs">
                                <ListItemButton>
                                  <ListItemIcon><PersonIcon /></ListItemIcon>
                                  Gérer les utilisateurs
                                </ListItemButton>
                              </Link>
                          </ListItem>
                        : 
                          ""
                      }
                      { (law.filter((law : Law)=>(law.uid === newsConfig)).length === 1) ? 
                          <ListItem
                            disablePadding
                            className={style.listitem + ' ' + (location.pathname === '/accueilConfig' ? style.active : '')}
                          >
                            <Link onClick={closeDrawer} to="/accueilConfig">
                              <ListItemButton className='window'>
                                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                                Gérer les actualités
                              </ListItemButton>
                            </Link>
                          </ListItem>
                        : 
                          ""
                      }
                      { (law.filter((law : Law)=>(law.uid === salepointConfig)).length === 1) ? 
                          <ListItem
                            disablePadding
                            className={style.listitem + ' ' + (location.pathname === '/concession' ? style.active : '')}
                          >
                            <Link onClick={closeDrawer} to="/concession">
                              <ListItemButton >
                                <ListItemIcon><EmojiTransportationIcon /></ListItemIcon>
                                Gérer les concessions
                              </ListItemButton>
                            </Link>
                          </ListItem>
                        : 
                          ""
                      }
                      { (law.filter((law : Law)=>(law.uid === popinConfig)).length === 1) ? 
                          <ListItem
                              disablePadding
                              className={style.listitem + ' ' + (location.pathname === '/popins' ? style.active : '')}
                            >
                        
                          <Link onClick={closeDrawer} to="/popins">
                            <ListItemButton className='window'>
                              <ListItemIcon><WysiwygIcon /></ListItemIcon>
                              Gérer les popins
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        : 
                          ""
                      }
                    </List>
                  </Collapse>
                </>
              : 
                ""
              }
              <hr />

              <div className={style.boxNavOut}>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="https://sites.google.com/jeanrouyerautomobiles.fr/base-documentaire/accueil" target="_blank">
                    <ListItemButton className='window'>
                      <div>
                        <img src={base_doc} className={style.iconNavOut} alt="Base documentaire" />
                        Base documentaire
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="https://www.jeanrouyerautomobiles.fr/recrutement/recrutement/offres-demploi/" target="_blank">
                    <ListItemButton className='window'>
                      <div>
                        <img src={offres_emploi} className={style.iconNavOut} alt="Offres d'emploi" />
                        Offres d'emploi
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="/accueilConfig">
                    <ListItemButton className='window'>
                      <div>
                        <img src={handicap} className={style.iconNavOut} alt="Accompagnement handicap" />
                        Accompagnement handicap
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem
                  disablePadding
                  className={style.listitem}
                >
                  <Link to="/accueilConfig">
                    <ListItemButton className='window'>
                      <div>
                        <img src={e_learning} className={style.iconNavOut} alt="E-learning" />
                        E-learning
                      </div>
                      <OpenInNewIcon />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </div>
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  )
};

export default Navbar;