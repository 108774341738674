import Service_Api from "../../services/Api"
import { useEffect, useState } from "react"
import { useAuth } from "../../services/auth/useAuth";
import { App } from "../../services/object/App";
import { Law } from "../../services/object/Law";
import { User } from "../../services/object/User";
import { Job } from "../../services/object/Job";
import { useJob } from "../../services/auth/useJob";

export default function CrossOrigin() {
    const Api = Service_Api()
    const [connected, setConnected] = useState<boolean>();

    let {user, law, TOApp, token} = useAuth();
    let {job} = useJob();

    const isConnected = async () => {
        setConnected((await Api.get('user/stillConnected', '?jwt='+token))?.success as boolean);
    }
    isConnected();
    useEffect(()=>{
        if(typeof connected !== "undefined"){
            try{
                let dataParent : {token :string, user: User|null, job: Job|null, law: Law[]|null, app :App[]|null};
                if(connected){
                    dataParent = {token: token, user: user, law : law, app: TOApp, job: job}
                    setTimeout(()=>{
                        window.parent.postMessage(JSON.stringify(dataParent), document.referrer);
                    }, 1000)
                }else{
                    dataParent = {token: "", user:null, law : null, app: null, job : null}
                    window.parent.postMessage(JSON.stringify(dataParent), document.referrer);
                }
            }catch(error){
                console.log(error)
            }
        }
    }, [connected])

    return <></>;
}