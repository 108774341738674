export const displayFormErrors = (formRef : React.RefObject<HTMLFormElement>) => {
    const formControls = formRef.current?.elements;
    const errors: Record<string, string> = {};

    if (formControls) {
        for (const control of formControls) {
        if (control instanceof HTMLInputElement || control instanceof HTMLTextAreaElement) {
            if (!control.checkValidity()) {
                errors[control.name] = control.validationMessage;
            }
        }
        }
    }
    return errors;
};