import * as React from 'react';
import { Salepoint } from '../../services/object/Salepoint';
import { ChildProps } from './Popins';
import { Role } from '../../services/object/Role';
import { Popin } from '../../services/object/Popin';
import { Job } from '../../services/object/Job';
import { Environnement } from '../../services/object/Environnement';
import style from './Popin.module.scss'
import FormPopin from './FormPopin';
import CheckboxGroup from '../../components/input/CheckboxGroup';
import IndeterminateCheckbox from '../../components/input/IndeterminateCheckbox'
import { Univers } from '../../services/object/Univers';
import { App } from '../../services/object/App';


  interface ModalPopinProps {
    errorMessages: Record<string, string>,
    
    checkedEnvironnement: string[],
    setCheckedEnvironnement: React.Dispatch<React.SetStateAction<string[]>>,
    environnement: Environnement[],
    
    checkedJob: string[],
    setCheckedJob: React.Dispatch<React.SetStateAction<string[]>>,
    job:Job[],

    checkedSalepoint: string [],
    checkedUnivers: string [],
    setChildCheckedSalepoint: React.Dispatch<React.SetStateAction<ChildProps[]>>,
    childCheckedSalepoint: ChildProps[],
    T_O_salepoint:Salepoint[],
    univers:Univers[],

    checkedRole: string[],
    checkedApp: string [],
    setChildCheckedRole: React.Dispatch<React.SetStateAction<ChildProps[]>>,
    childCheckedRole: ChildProps[],
    role:Role[],
    app:App[],
    
    formPopins: Popin,
    handlePopinChange: (property: string, value: string) => void,
    formRefPopin: React.RefObject<HTMLFormElement>,
    formRefRole: React.RefObject<HTMLFormElement>,
    isRowClicked: boolean,
    formRefSalepoint: React.RefObject<HTMLFormElement>,
  }

  export default function ModalPopin({
    errorMessages, 
    checkedSalepoint,
    checkedUnivers,
    checkedEnvironnement,
    checkedJob,
    setChildCheckedSalepoint,
    childCheckedSalepoint,
    T_O_salepoint,
    univers,
    checkedRole,
    checkedApp,
    setChildCheckedRole,
    childCheckedRole,
    role,
    app,
    setCheckedEnvironnement,
    environnement,
    formPopins,
    handlePopinChange,
    formRefPopin, 
    formRefRole,
    isRowClicked,
    formRefSalepoint,
    job,
    setCheckedJob
}: ModalPopinProps) {
    

    return ([
        <>
            <form className={style.uiform} ref={formRefPopin}>
                <FormPopin formPopins={formPopins} onChange={handlePopinChange} errorMessages={errorMessages} isRowClicked={isRowClicked}/>
            </form>
        </>
        ,
        <>
        <form className={style.uiform} ref={formRefSalepoint}>
            <div className={style.checkContenair}>
                <CheckboxGroup
                    objetParent={univers}
                    objetChild={T_O_salepoint}
                    objectParentName="univers"
                    legend="Point de Vente"
                    childChecked={childCheckedSalepoint}
                    setChildChecked={setChildCheckedSalepoint}
                    checkedOfChild={checkedSalepoint}
                    checkedOfParent={checkedUnivers}
                    errorMessages={errorMessages}
                    required={true}
                />
                <IndeterminateCheckbox
                    Objet={environnement} 
                    legend="Environnement"
                    setCheckedObject={setCheckedEnvironnement}    
                    checkedObject={checkedEnvironnement}
                    errorMessages={errorMessages}
                    required={false}
                />       
            </div> 
        </form>
        </>
        ,
        <>
        <form className={style.uiform} ref={formRefRole}>
            <div className={style.checkContenair}>
                <CheckboxGroup
                    objetParent={app}
                    objetChild={role}
                    objectParentName="app"
                    legend="Role"
                    childChecked={childCheckedRole}
                    setChildChecked={setChildCheckedRole}
                    checkedOfChild={checkedRole}
                    checkedOfParent={checkedApp}
                    errorMessages={errorMessages}
                    required={true}
                    initialVisibility={true}
                />
                <IndeterminateCheckbox
                    Objet={job} 
                    legend="Métier"
                    setCheckedObject={setCheckedJob}    
                    checkedObject={checkedJob}
                    errorMessages={errorMessages}
                    required={true}  
                />     
            </div> 
        </form>
        </>
     ]);
  }

