
import JRAHarmonieLogo from './assets/img/jra-harmonie-shortcut-icon.png';
import style from './assets/scss/loader.module.scss';

interface LoaderProps {
    position?: string;
  }
  
export const Loader: React.FC<LoaderProps> = ({ position }) => {
    return (
        <div id={style.harmonieLoader} className={(position == "form" ? style.formLoader : "")}>
            <div className={style.content}>
                <div className={style.image}>
                    <img src={JRAHarmonieLogo} alt="logo" />
                </div>
                <div className={style.wrapper}>
                    <div className={style.satWrap}>
                        <div className={style.sat}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}