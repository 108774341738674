
import React, { useState, useEffect, ChangeEvent } from "react";
import { App } from "../../services/object/App";
import style from "./assets/style/profile.module.scss";

import AppForm from "./AppForm";

interface ModalSuggestedAppProps {
  errorMessages: Record<string, string>;
  handleAppChange: (property: string, value: string) => void;
  appForm: App | undefined;
  refAppForm: React.RefObject<HTMLFormElement>;
}

export default function ModalSuggestedApp({
  errorMessages,
  handleAppChange,
  appForm,
  refAppForm
}: ModalSuggestedAppProps) {


  return [
    <form className={`${style.uiform} ${style.appForm}`} ref={refAppForm}>
      <AppForm
        onChange={handleAppChange}
        appForm={appForm}
        errorMessages={errorMessages}
      />
    </form>
  ];
};
