import clsx from "clsx";
import { Button } from "@mui/material";
import style from "./modal.module.scss";
import { Modal } from "@mui/base/Modal";
import { ReactComponent as Close } from "../../pages/assets/closeWindows.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CheckIcon from "@mui/icons-material/Check";
import React, { forwardRef, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

interface ModalFormComponentProps {
  onFormSubmitSuccess: (isDraft: boolean) => void;
  onClose: () => void;
  modalOpen: boolean;
  title: string | null;
  subtitle?: string[] | null;
  childrenForm: JSX.Element[] | undefined;
  formEvent: (() => Promise<boolean>)[] | undefined;
  stepperLength?: number;
  activeStep?: { index: number; appUid: string };
  setActiveStep?: React.Dispatch<
    React.SetStateAction<{ index: number; appUid: string } | undefined>
  >;
  setErrorMessages: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  alternativeStyle?: {
    readonly [key: string]: string;
  }
  modalType?: string;
}

export default function ModalFormComponent({
  onFormSubmitSuccess,
  onClose,
  modalOpen,
  title = null,
  subtitle = null,
  childrenForm,
  formEvent,
  stepperLength,
  activeStep,
  setActiveStep,
  setErrorMessages,
  modalType = "",
                                              alternativeStyle}: ModalFormComponentProps) {
  const [formCount, setFormCount] = useState(0);
  const [totalFormCount, setTotalFormCount] = useState(100);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (childrenForm) setTotalFormCount(childrenForm.length - 1);
  }, [childrenForm]);

  useEffect(() => {
    setFormCount(0);
  }, [modalOpen]);

  const handleFormSubmitSuccess = async (isDraft: boolean) => {
    setIsSubmitting(true);
    await onFormSubmitSuccess(isDraft);
    setIsSubmitting(false);
  };
  const onClickPrevButton = () => {
    setErrorMessages({});
    // Vérifie si setActiveStep est défini et si l'index de activeStep est égal à 0
    if (typeof setActiveStep !== "undefined" && activeStep?.index === 0) {
      //Si c'est le cas, définis setActiveStep sur undefined pour réinitialiser l'étape avtive
      setActiveStep(undefined);
    }
    //vérifie si setActiveStep est défini et si l'index de activeStep est égal à stepperLength ou 0 si stepperLength est null ou undefined
    else if (
      typeof setActiveStep !== "undefined" &&
      activeStep?.index === (stepperLength ?? 0)
    ) {
      //Si c'est le cas, décrémente l'index de activeStep et met à jour setActiveStep avec la nouvelle valeur
      setActiveStep({ index: (stepperLength ?? 0) - 1, appUid: "" });
    }
    setFormCount(formCount - 1);
  };
  const onClickNextButton = async () => {
    // Vérifie si setActiveStep est défini et si activeStep est défini
    if (typeof setActiveStep !== "undefined" && activeStep) {
      //Si c'est le cas, met à jour setActiveStep avec l'index suivant et une nouvelle valeur pour appUid
      setActiveStep({ index: stepperLength ?? 0, appUid: "" });
    }
    if (formEvent) {
      if (await formEvent[formCount]()) {
        setErrorMessages({});
        setFormCount(formCount + 1);
      }
    }
  };

  return (
      <Modal className={`${style.StyledModal} ${modalType == "postConfig" ? style.postConfigModal : ""}`}
             aria-labelledby="unstyled-modal-title"
             aria-describedby="unstyled-modal-description"
             open={modalOpen}
             onClose={onClose}
             slots={{ backdrop: Backdrop }}>
        <div className={`${style.box}`}>
          <div className={`${style.modalHeader} ${isSubmitting ? style.isSubmitting : ""} `}>
            <Tooltip title="Fermer">
              <button className={`${style.close}`} onClick={onClose}><Close/></button>
            </Tooltip>
            <h2 className={`${style.title}`}>{ title }</h2>
            {subtitle && <h3 className={`${style.subtitle}`}>{subtitle[formCount]}</h3>}
          </div>

          <div className={`${style.respscroll}`}>
            {(childrenForm && childrenForm[formCount] ) ? childrenForm[formCount] : ""}
          </div>

          <div className={`${style.fullSizeBox} ${alternativeStyle?.fullSizeBox}`}>
            {/* Ajoute la classe offscreen si formCount est égal à 0, sinon une chaîne vide , ajoute une chaîne vide si l'index de activeStep est égal à 0, activeStep est undefined ou l'index de activeStep est égal à stepperLength, sinon ajoute la classe unvisibility */}      
              {((formCount !== 0) ?
                  <Button className={`${style.buttonNS}`} variant="contained" color='primary' onClick={onClickPrevButton}> 
                    <NavigateBeforeIcon/><span> Précédent</span> 
                  </Button>
              : "")} 
  
              
                {/* Ajoute la classe firstButton si formCount est égal à 0, sinon une chaîne vide, ajoute une chaîne vide si activeStep est défini et si son index est supérieur ou égal à (stepperLength - 1), sinon ajoute la classe unvisibility */}
              {(formCount !== totalFormCount) ? 
                <Button className={`${style.buttonNS}`} variant="contained" type="submit" onClick={onClickNextButton}> 
                  <span>Suivant </span> <NavigateNextIcon/> 
                </Button>
              : 
                ""
              }
              {formCount === totalFormCount && modalType == "postConfig" && (
                <Button
                  className={`${style.buttonNS} ${style.buttonDraft}`}
                  variant="contained"
                  type="submit"
                  onClick={() => handleFormSubmitSuccess(true)}
                >
                  <span>Brouillon</span>
                </Button>
              )}
              {formCount === totalFormCount && (
                <Button
                  className={`${style.buttonNS} ${style.buttonPublish}`}
                  variant="contained"
                  type="submit"
                  onClick={() => handleFormSubmitSuccess(false)}
                  endIcon={<CheckIcon />}
                >
                  <span>
                    {modalType === "postConfig"
                      ? "Publier " 
                      : modalType === "postSuggestion"
                      ? "Soumettre " 
                      : "Terminer"}
                  </span>
                </Button>
              )}
            </div>
          </div>
      </Modal>
  );
}

const Backdrop = forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={`MuiBackdrop-open Backdrop ${style.Backdrop}`}
      ref={ref}
      {...other}
    />
  );
});
